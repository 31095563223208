import {
  AdvertisementSource,
  DocumentSignatureRequest,
  ProofOfFund,
  SecurityServiceResponse,
  CustomerProfileClient,
  File,
  Profile,
  ProfileDocument,
  ProfileFund,
  ProfileQuestion,
  SignatureTransactionRequest,
  SignatureTransactionResponse
} from './../MIF.Subscription.WebApi';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { MyContactsFormModel } from '@my-details/contacts/contacts.component';
import { MyFinancialFormModel } from '@my-details/financial-situation/financial-situation.component';
import { MyDetailsFormModel } from '@my-details/my-details.component';
import { collections } from '@constants/collections';
import {
  AuthModel,
  BinificiarGfdModel,
  ClauseNominativeModel,
  getAdvertisementSourceDataModel,
  getAuthModel,
  getHealthQuestionsModel,
  getMyGfdRateModel,
  getMyRateModel,
  getPaymentPlanModel,
  getProfileFondsModel,
  getProfileQuestionsModel,
  getRegistrationModel,
  getResidenceQuestionsModel,
  getUpdatedByBenificiarGfdModelProfileModel,
  getUpdatedByClauseNominativeModelProfileModel,
  getUpdatedByDistibutedFondsModelProfileModel,
  getUpdatedByFondsRepartitionModelProfileModel,
  getUpdatedByGpaSubscriptionModelProfileModel,
  getUpdatedByHealthQuestionsModelProfileModel,
  getUpdatedByMyBankDetailsDataModelProfileModel,
  getUpdatedByMyContactsModelProfileModel,
  getUpdatedByMyDetailsModelProfileModel,
  getUpdatedByMyFinancialModelProfileModel,
  getUpdatedByMyRateGfdModelProfileModel,
  getUpdatedByMyRateModelProfileModel,
  getUpdatedByPaymentPlanModelProfileModel,
  getUpdatedByPrimaryBeneficiarModelProfileModel,
  getUpdatedByProfileQuestionModelProfileModel,
  getUpdatedByRegistrationModelProfileModel,
  getUpdatedByResidenceQuestionsModelProfileModel,
  HealthQuestionsModel,
  MyRateGfdModel,
  MyRateModel,
  PaymentPlanModel,
  RegistrationModel,
  ResidenceQuestionsModel
} from '../../extensions/user-data-extensions';
import { ProfileStepCode } from '@models/profile-step-code';
import { FondsRepartition, Informations } from '../MIF.Subscription.Parrot';
import { MyBankDetailsDataModel } from '@my-details/bank-details/bank-details.component';
import { AbstractControl } from '@angular/forms';
import { environment } from '@environments/environment';
import { constants } from '@constants/constants';
import { InvestmentProfile } from '@my-profile/profile-summary/profile-summary.component';
import { Observable, Subject } from 'rxjs';
import { BeneficiareFormModel } from '@my-beneficiaries/clause-nominative/clause-nominative.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertModalComponent, modalTypes } from '@modals/alert-modal/alert-modal.component';
import { LabelTextPipe } from '@pipes/label-text.pipe';
import { InformationsType, WsCRMClientService } from './ws-crmclient.service';
import { AdvertisementSourceDataModel, GpaSubscriptionModel } from '@signature/signature.extentions';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  profileDocuments: any;

  constructor(private webApi: CustomerProfileClient, private wsCRMClientService: WsCRMClientService) {}

  public userData: Profile;
  public userId: string;
  private userDataCleared = new Subject<boolean>();

  userDataOnCleared(): Observable<any> {
    return this.userDataCleared.asObservable();
  }

  public async getSubscriptionContract(profileId: string, htmlContent: string): Promise<string> {
    return this.webApi.getSubscriptionContract(profileId, htmlContent).toPromise();
  }

  public async signDocument(request: DocumentSignatureRequest): Promise<SignatureTransactionResponse> {
    return this.webApi.signDocument(request).toPromise();
  }

  public async validateTransaction(signatureTransactionRequest: SignatureTransactionRequest): Promise<SignatureTransactionResponse> {
    return this.webApi.validateTransaction(signatureTransactionRequest).toPromise();
  }

  public async getClient(numberA: string): Promise<Profile> {
    return this.webApi
      .getClient(numberA)
      .pipe(
        map((profile: Profile) => {
          if (this.userId) {
            profile.uniqueId = this.userId; // in case go back after creating user
          }
          if (this.userData?.socialNumberA !== numberA) {
            // update profile when checking only in case it's an other user
            this.userData = profile;
          }

          return profile;
        })
      )
      .toPromise();
  }

  public async updateProfileDocuments(profileId: string, profileDocuments: ProfileDocument[]): Promise<ProfileDocument[]> {
    return this.webApi.updateProfileDocuments(profileId, profileDocuments).toPromise();
  }

  public async getFile(fileUniqueId: string): Promise<File> {
    return this.webApi.getFile(fileUniqueId).toPromise();
  }

  public async getSignedDocument(): Promise<File> {
    return this.webApi.getSignedDocument(this.userId).toPromise();
  }

  public async updateProofOfFundsDocuments(profileId: string, profileDocuments: ProofOfFund[]) {
    const docs = await this.webApi.updateAssetDocuments(profileId, profileDocuments).toPromise();

    if (docs) {
      this.userData.proofOfFunds = docs;
      this.userId = this.userData.uniqueId;
    }

    return !!docs;
  }

  public async clearAllData(): Promise<any> {
    return new Promise<any>(resolve => {
      this.userData = null;
      this.userId = null;
      this.userDataCleared.next(true);
      resolve(true);
    });
  }

  public async getUserData(profileUniqueId?: string): Promise<Profile> {
    if (!profileUniqueId) {
      profileUniqueId = this.userId;
    }

    if (!profileUniqueId) {
      return null;
    }

    return this.webApi
      .getUserData(profileUniqueId)
      .pipe(
        map((profile: Profile) => {
          this.userData = this.checkAndDeleteSkippedItems(profile);
          this.userId = this.userData.uniqueId;
          return this.userData;
        })
      )
      .toPromise();
  }

  private checkAndDeleteSkippedItems(profile: Profile): Profile {
    let items = this.wsCRMClientService.skippedItemsData?.[InformationsType.NET_MONTHLY_INCOME];
    if (
      items &&
      items?.findIndex((item: Informations) => {
        return item.id === profile.professionalInfo?.personalMonthlyNetIncomeCode;
      }) !== -1
    ) {
      if (profile.professionalInfo) {
        profile.professionalInfo.personalMonthlyNetIncomeCode = null;
      }
    }

    if (
      items &&
      items?.findIndex((item: Informations) => {
        return item.id === profile.professionalInfo?.householdMonthlyNetIncomeCode;
      }) !== -1
    ) {
      if (profile.professionalInfo) {
        profile.professionalInfo.householdMonthlyNetIncomeCode = null;
      }
    }

    items = this.wsCRMClientService.skippedItemsData?.[InformationsType.FAMILY_SITUATION];
    if (
      items &&
      items?.findIndex((item: Informations) => {
        return item.id === profile.maritalStatusCode;
      }) !== -1
    ) {
      profile.maritalStatusCode = null;
    }

    items = this.wsCRMClientService.skippedItemsData?.[InformationsType.CIVILITY];
    if (
      items &&
      items?.findIndex((item: Informations) => {
        return item.id === profile.person.civilityCode;
      }) !== -1
    ) {
      if (profile.person) {
        profile.person.civilityCode = null;
      }
    }

    items = this.wsCRMClientService.skippedItemsData?.[InformationsType.MATRIMONIAL_REGIME];
    if (
      items &&
      items?.findIndex((item: Informations) => {
        return item.id === profile.matrimonialRegimeCode;
      }) !== -1
    ) {
      profile.matrimonialRegimeCode = null;
    }

    items = this.wsCRMClientService.skippedItemsData?.[InformationsType.INDUSTRY];
    if (
      items &&
      items?.findIndex((item: Informations) => {
        return item.id === profile.professionalInfo?.activitySectorCode;
      }) !== -1
    ) {
      if (profile.professionalInfo) {
        profile.professionalInfo.activitySectorCode = null;
      }
    }

    return profile;
  }

  public async saveProfile(profileModel: Profile) {
    delete profileModel.profileStatus;

    return this.webApi
      .saveProfile(environment.orgId, profileModel)
      .pipe(
        map((response: Profile) => {
          this.userData = response;
          this.userId = this.userData.uniqueId;
          return this.userData;
        })
      )
      .toPromise();
  }

  public getAuthModel(): AuthModel {
    return getAuthModel(this.userData);
  }

  public getRegistrationModel(): RegistrationModel {
    return getRegistrationModel(this.userData);
  }

  public async updateRegistrationModel(model: RegistrationModel, contractNumber?: number) {
    const profileData = this.getProfileData();
    const profile = getUpdatedByRegistrationModelProfileModel(model, profileData, contractNumber);

    Object.assign(profile, {
      profileQuestions: profile.profileQuestions ? profile.profileQuestions : [],
      beneficiaries: profile.beneficiaries ? profile.beneficiaries : [],
      profileFunds: profile.profileFunds ? profile.profileFunds : []
    });

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    return this.userId;
  }

  public getResidenceQuestionsModel(): ResidenceQuestionsModel {
    return getResidenceQuestionsModel(this.userData);
  }

  public async updateResidenceQuestionsModel(model: ResidenceQuestionsModel) {
    if (!this.userId) {
      return null;
    }

    const profileData = this.getProfileData();
    const profile = getUpdatedByResidenceQuestionsModelProfileModel(model, profileData);

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    return this.userId;
  }

  public async updateHealthQuestionsModel(model: HealthQuestionsModel, isGfd: boolean = false) {
    if (!this.userId) {
      return null;
    }

    const profileData = this.getProfileData();
    const profile = getUpdatedByHealthQuestionsModelProfileModel(model, profileData, isGfd);

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    return this.userId;
  }

  public getHealthQuestionsModel(isGfd: boolean = false): HealthQuestionsModel {
    return getHealthQuestionsModel(this.userData, isGfd);
  }

  public async updateMyRateModel(model: MyRateModel) {
    if (!this.userId) {
      return null;
    }

    const profileData = this.getProfileData();
    const profile = getUpdatedByMyRateModelProfileModel(model, profileData);

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    return this.userId;
  }

  public updateMyRateModelWithoutSaving(profile: Profile, model: MyRateModel) {
    if (!this.userId) {
      return null;
    }

    const profileData = Object.assign({}, profile);
    const profileWithUpdates = getUpdatedByMyRateModelProfileModel(model, profileData);

    return profileWithUpdates;
  }

  public async updateMyRateGfdModel(model: MyRateGfdModel) {
    if (!this.userId) {
      return null;
    }

    const profileData = this.getProfileData();
    const profile = getUpdatedByMyRateGfdModelProfileModel(model, profileData);

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    return this.userId;
  }

  public getMyRateModel(): MyRateModel {
    return getMyRateModel(this.userData);
  }

  public getMyGfdRateModel(): MyRateGfdModel {
    return getMyGfdRateModel(this.userData);
  }

  public async updatePaymentPlanModel(model: PaymentPlanModel) {
    if (!this.userId) {
      return null;
    }

    const profileData = this.getProfileData();
    const profile = getUpdatedByPaymentPlanModelProfileModel(model, profileData);

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    return this.userId;
  }

  public updatePaymentPlanModelWithoutSaving(profile: Profile, model: PaymentPlanModel): Profile {
    if (!this.userId) {
      return null;
    }

    const profileData = Object.assign({}, profile);
    const profileWithUpdates = getUpdatedByPaymentPlanModelProfileModel(model, profileData);

    return profileWithUpdates;
  }

  public getPaymentPlanModel(): PaymentPlanModel {
    return getPaymentPlanModel(this.userData);
  }

  public async updateQuestionsModel(model: ProfileQuestion[]) {
    if (!this.userId) {
      return null;
    }

    const profileData = this.getProfileData();
    const profile = getUpdatedByProfileQuestionModelProfileModel(model, profileData);

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    return this.userData;
  }

  public getProfileQuestionsModel(): ProfileQuestion[] {
    return getProfileQuestionsModel(this.userData);
  }

  public async updateFondsModel(model: FondsRepartition[], investmentProfile: InvestmentProfile, newProfileTypeCode: number, isRecommendationsFollowed: boolean) {
    if (!this.userId) {
      return null;
    }

    const profileData = this.getProfileData();
    const isGestionSousMandat = investmentProfile.managementTypeCode === constants.gestionSousMandat.id;
    const isGestionHorizon = investmentProfile.managementTypeCode === constants.gestionHorizon.id;
    const isTypeChangedToLibre = profileData.managementTypeCode === constants.gestionSousMandat.id && investmentProfile.managementTypeCode === constants.gestionLibre.id;
    const isRecommendationsFollowedChangedToFalse = profileData.isRecommendationsFollowed === true && isRecommendationsFollowed === false;
    const profile = getUpdatedByFondsRepartitionModelProfileModel(model, profileData, isRecommendationsFollowed, isGestionSousMandat, isGestionHorizon);

    if (investmentProfile.managementTypeCode === constants.gestionLibre.id && isRecommendationsFollowedChangedToFalse) {
      profile.profileFunds = [];
    }
    if (isTypeChangedToLibre && isRecommendationsFollowed === false) {
      profile.profileFunds = [];
    }

    profile.selectedInvestmentProfileId = investmentProfile.selectedProfileId;
    profile.recommendedInvestmentProfileId = investmentProfile.recommendedProfileId;
    profile.managementTypeCode = investmentProfile.managementTypeCode;
    profile.profileTypeCode = newProfileTypeCode;
    profile.isRecommendationsFollowed = isRecommendationsFollowed;

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;
    return this.userId;
  }

  public updateFondsModelWithoutSaving(profile: Profile, model: FondsRepartition[], investmentProfile: InvestmentProfile, newProfileTypeCode: number, isRecommendationsFollowed: boolean): Profile {
    if (!this.userId) {
      return null;
    }

    const profileData = Object.assign({}, profile);
    const isGestionSousMandat = investmentProfile.managementTypeCode === constants.gestionSousMandat.id;
    const isGestionHorizon = investmentProfile.managementTypeCode === constants.gestionHorizon.id;
    const isTypeChangedToLibre = profileData.managementTypeCode === constants.gestionSousMandat.id && investmentProfile.managementTypeCode === constants.gestionLibre.id;
    const isRecommendationsFollowedChangedToFalse = profileData.isRecommendationsFollowed === true && isRecommendationsFollowed === false;
    const profileWithUpdates = getUpdatedByFondsRepartitionModelProfileModel(model, profileData, isRecommendationsFollowed, isGestionSousMandat, isGestionHorizon);

    if (investmentProfile.managementTypeCode === constants.gestionLibre.id && isRecommendationsFollowedChangedToFalse) {
      profileWithUpdates.profileFunds = [];
    }

    if (isTypeChangedToLibre && isRecommendationsFollowed === false) {
      profileWithUpdates.profileFunds = [];
    }

    profileWithUpdates.selectedInvestmentProfileId = investmentProfile.selectedProfileId;
    profileWithUpdates.recommendedInvestmentProfileId = investmentProfile.recommendedProfileId;
    profileWithUpdates.managementTypeCode = investmentProfile.managementTypeCode;
    profileWithUpdates.profileTypeCode = newProfileTypeCode;
    profileWithUpdates.isRecommendationsFollowed = isRecommendationsFollowed;

    return profileWithUpdates;
  }

  public async updateDistibutionFondsModel(model: AbstractControl[]) {
    if (!this.userId) {
      return null;
    }

    const profileData = this.getProfileData();
    const profile = getUpdatedByDistibutedFondsModelProfileModel(model, profileData, true);

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;
    return this.userId;
  }

  public updateDistibutionFondsModelWithoutSaving(profile: Profile, model: AbstractControl[]): Profile {
    if (!this.userId) {
      return null;
    }

    const profileData = Object.assign({}, profile);
    const profileWithUpdates = getUpdatedByDistibutedFondsModelProfileModel(model, profileData, false);

    return profileWithUpdates;
  }

  public async updateLastChangedStep(step: ProfileStepCode) {
    if (!this.userId) {
      return null;
    }

    const profile = this.getProfileData();

    profile.profileStepCode = step;

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;
    return this.userId;
  }

  public async updateBeneficiaryClauseCode(code: number) {
    if (!this.userId) {
      return null;
    }

    const profile = this.getProfileData();

    profile.beneficiaryClauseCode = code;
    profile.profileStepCode = ProfileStepCode.MyBeneficiaries;

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;
    return this.userId;
  }

  public async updateBeneficiaryGfdCode(code: number) {
    if (!this.userId) {
      return null;
    }

    const profile = this.getProfileData();

    profile.gfdProfile.beneficiaryTypeCode = code;

    profile.profileStepCode = ProfileStepCode.MyBeneficiaries;

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    return this.userId;
  }

  public async updateBenificiarGfdModel(model: BinificiarGfdModel) {
    if (!this.userId) {
      return null;
    }

    const profileData = this.getProfileData();
    const profile = getUpdatedByBenificiarGfdModelProfileModel(model, profileData);

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    return this.userId;
  }

  public async sendOTP(profileId: string): Promise<SecurityServiceResponse> {
    return this.webApi.sendOTP(profileId).toPromise();
  }

  public async checkOTP(profileId: string, transactionId: string | null | undefined, signatoryId: string | null | undefined, otp: string | null | undefined): Promise<SecurityServiceResponse> {
    return this.webApi.checkOTP(profileId, transactionId, signatoryId, otp).toPromise();
  }

  public async updateClauseNominativeModel(model: ClauseNominativeModel, isGpa: boolean) {
    if (!this.userId) {
      return null;
    }

    const profileData = this.getProfileData();
    const profile = getUpdatedByClauseNominativeModelProfileModel(model, profileData, isGpa);

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    return this.userId;
  }

  public async updatePrimaryBeneficiar(model: BeneficiareFormModel) {
    if (!this.userId) {
      return null;
    }

    const profileData = this.getProfileData();
    const profile = getUpdatedByPrimaryBeneficiarModelProfileModel(model, profileData);

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    return this.userId;
  }

  public async updateCimsBeneficiarContractEndDate(date: Date) {
    if (!this.userId) {
      return null;
    }

    const profileData = this.getProfileData();

    profileData.cimsProfile.contractExpirationDate = date;

    this.userData = await this.saveProfile(profileData);
    this.userId = this.userData.uniqueId;

    return this.userId;
  }

  public async updateGpaSubscription(model: GpaSubscriptionModel) {
    if (!this.userId) {
      return null;
    }

    const profileData = this.getProfileData();
    const profile = getUpdatedByGpaSubscriptionModelProfileModel(model, profileData);

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    return this.userId;
  }

  public updateGpaSubscriptionWithoutSaving(profile: Profile, model: GpaSubscriptionModel): Profile {
    if (!this.userId) {
      return null;
    }

    const profileData = Object.assign({}, profile);
    const profileWithUpdates = getUpdatedByGpaSubscriptionModelProfileModel(model, profileData);

    return profileWithUpdates;
  }

  public async clearGpaSubscription() {
    if (!this.userId) {
      return null;
    }

    const profileData = this.getProfileData();

    profileData.gpaProfile = null;

    this.userData = await this.saveProfile(profileData);
    this.userId = this.userData.uniqueId;

    return this.userId;
  }

  public async updateClauseLibreModel(beneficiaryFreeClauseText: string) {
    if (!this.userId) {
      return null;
    }

    const profile = this.getProfileData();
    profile.beneficiaryFreeClauseText = beneficiaryFreeClauseText;
    profile.profileStepCode = ProfileStepCode.MyBeneficiariesLibre;

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    return this.userId;
  }

  public async updateMyDetailsModel(model: MyDetailsFormModel, bsModalService: BsModalService, labelTextPipe: LabelTextPipe): Promise<any> {
    if (!this.userId) {
      return null;
    }

    let uploadDocsIssue: boolean;
    const profileData = this.getProfileData();
    const profile = getUpdatedByMyDetailsModelProfileModel(model, profileData);
    this.userData = await this.saveProfile(profile);
    const promises: Promise<ProfileDocument>[] = [];

    if (model.firstIdentificationDocumentType !== undefined && model.firstIdentificationDocumentFiles) {
      model.firstIdentificationDocumentFiles.forEach(async (file: any) => {
        promises.push(
          new Promise<any>(resolve => {
            this.getPreparedToSaveFile(file, collections.profileDocuments.firstIdentificationDocument.typeId, model.firstIdentificationDocumentType, this.userData, resolve);
          })
        );
      });
    }

    return await Promise.all(promises).then(async profileDocuments => {
      const docsToBeUpload = profileDocuments.concat(
        this.userData.profileDocuments.filter((item: ProfileDocument) => {
          return item.typeId !== collections.profileDocuments.firstIdentificationDocument.typeId && item.typeId !== collections.profileDocuments.secondIdentificationDocument.typeId;
        })
      );
      const docsToBeUploadFiltered = docsToBeUpload.filter((doc: ProfileDocument) => {
        if (doc.uniqueId) {
          delete doc.file.base64Content;
        }
        return (doc.uniqueId === undefined && doc.file?.base64Content !== undefined) || (doc.uniqueId !== undefined && doc.file?.base64Content === undefined) || doc.uniqueId;
      });
      const docsToBeIgnored = docsToBeUpload.filter((doc: ProfileDocument) => {
        return (doc.uniqueId === undefined && doc.file?.base64Content === undefined) || (doc.uniqueId === undefined && doc.file?.base64Content === '');
      });

      if (docsToBeIgnored?.length > 0) {
        const modal = bsModalService.show(AlertModalComponent, {
          initialState: {
            type: modalTypes.error,
            title: `${labelTextPipe.transform('Common.DocsIgnoredModalTitle')} :`,
            body: this.prepareIgnoredFilesModalBody(docsToBeIgnored)
          },
          class: 'modal-lg'
        });

        modal.content.onClose.subscribe(async () => {});
        return null;
      } else {
        uploadDocsIssue = !(await this.uploadDocs(docsToBeUploadFiltered));
        return uploadDocsIssue ? null : this.userId;
      }
    });
  }

  private prepareIgnoredFilesModalBody(docsToBeIgnored: any[]): string {
    let body = '<span class="recap-promo compact"> <ul class="list-arrows">';

    docsToBeIgnored.forEach((doc: ProfileDocument) => {
      body += `<li> ${doc.file?.fileName} </li>`;
    });

    body += ' </ul> </span>';

    return body;
  }

  private async uploadDocs(docsToBeUploadFiltered: ProfileDocument[]) {
    const docs = await this.updateProfileDocuments(this.userId, docsToBeUploadFiltered);

    if (docs) {
      this.userData.profileDocuments = docs;
      this.userId = this.userData.uniqueId;
    }

    return !!docs;
  }

  public async updateMyContactsModel(model: MyContactsFormModel, bsModalService: BsModalService, labelTextPipe: LabelTextPipe): Promise<any> {
    if (!this.userId) {
      return null;
    }

    let uploadDocsIssue: boolean;
    const profileData = this.getProfileData();
    const profile = getUpdatedByMyContactsModelProfileModel(model, profileData);
    this.userData = await this.saveProfile(profile);
    const promises: Promise<ProfileDocument>[] = [];

    if (model.addressConfirmationDocumentFiles) {
      model.addressConfirmationDocumentFiles.forEach(async (file: any) => {
        promises.push(
          new Promise<any>(resolve => {
            this.getPreparedToSaveFile(
              file,
              collections.profileDocuments.addressConfirmationDocument.typeId,
              collections.addressConfirmationDocumentTypes.find(d => d.name === 'JUSTIFICATIF_DOMICILE').id,
              this.userData,
              resolve
            );
          })
        );
      });
    }

    return await Promise.all(promises).then(async profileDocuments => {
      const docsToBeUpload = profileDocuments.concat(
        this.userData.profileDocuments.filter((item: ProfileDocument) => {
          return item.typeId !== collections.profileDocuments.addressConfirmationDocument.typeId;
        })
      );

      const docsToBeUploadFiltered = docsToBeUpload.filter((doc: ProfileDocument) => {
        if (doc.uniqueId) {
          delete doc.file.base64Content;
        }
        return (doc.uniqueId === undefined && doc.file?.base64Content !== undefined) || (doc.uniqueId !== undefined && doc.file?.base64Content === undefined) || doc.uniqueId;
      });

      const docsToBeIgnored = docsToBeUpload.filter((doc: ProfileDocument) => {
        return (doc.uniqueId === undefined && doc.file?.base64Content === undefined) || (doc.uniqueId === undefined && doc.file?.base64Content === '');
      });

      if (docsToBeIgnored?.length > 0) {
        const modal = bsModalService.show(AlertModalComponent, {
          initialState: {
            type: modalTypes.error,
            title: `${labelTextPipe.transform('Common.DocsIgnoredModalTitle')} :`,
            body: this.prepareIgnoredFilesModalBody(docsToBeIgnored)
          },
          class: 'modal-lg'
        });

        modal.content.onClose.subscribe(async () => {});
        return null;
      } else {
        uploadDocsIssue = !(await this.uploadDocs(docsToBeUploadFiltered));
        return uploadDocsIssue ? null : this.userId;
      }
    });
  }

  public updateMyContactsModelWithoutSaving(profile: Profile, model: MyContactsFormModel): Profile {
    if (!this.userId) {
      return null;
    }

    const profileData = Object.assign({}, profile);
    const profileWithUpdates = getUpdatedByMyContactsModelProfileModel(model, profileData);

    return profileWithUpdates;
  }

  private addEmptySurrenderLifeInsurancePolicyFile(proofsWithoutFiles: ProofOfFund[], model: MyFinancialFormModel, isNew: boolean) {
    const typeId = collections.proofOfFundDocuments.surrenderLifeInsurancePolicy.typeId;
    const array = this.userData.proofOfFunds.filter((item: ProofOfFund) => {
      return item.typeId === typeId;
    });

    if (array?.length > 0 && !isNew) {
      proofsWithoutFiles.push(
        new ProofOfFund({
          uniqueId: array[0].uniqueId,
          typeId: typeId,
          transactionDate: model.surrenderLifeInsurancePolicyDate,
          transactionAmount: model.surrenderLifeInsurancePolicyAmount
        })
      );
    } else {
      proofsWithoutFiles.push(
        new ProofOfFund({
          typeId: typeId,
          transactionDate: model.surrenderLifeInsurancePolicyDate,
          transactionAmount: model.surrenderLifeInsurancePolicyAmount
        })
      );
    }
  }

  private addEmptySavingsFile(proofsWithoutFiles: ProofOfFund[], model: MyFinancialFormModel, isNew: boolean) {
    const typeId = collections.proofOfFundDocuments.savings.typeId;
    const array = this.userData.proofOfFunds.filter((item: ProofOfFund) => {
      return item.typeId === typeId;
    });

    if (array?.length > 0 && !isNew) {
      proofsWithoutFiles.push(
        new ProofOfFund({
          uniqueId: array[0].uniqueId,
          typeId: typeId,
          transactionDate: model.savingsDate,
          transactionAmount: model.savingsAmount
        })
      );
    } else {
      proofsWithoutFiles.push(
        new ProofOfFund({
          typeId: typeId,
          transactionDate: model.savingsDate,
          transactionAmount: model.savingsAmount
        })
      );
    }
  }

  private addEmptySuccessionFile(proofsWithoutFiles: ProofOfFund[], model: MyFinancialFormModel, isNew: boolean) {
    const typeId = collections.proofOfFundDocuments.succession.typeId;
    const array = this.userData.proofOfFunds.filter((item: ProofOfFund) => {
      return item.typeId === typeId;
    });

    if (array?.length > 0 && !isNew) {
      proofsWithoutFiles.push(
        new ProofOfFund({
          uniqueId: array[0].uniqueId,
          typeId: typeId,
          transactionDate: model.successionDate,
          transactionAmount: model.successionAmount
        })
      );
    } else {
      proofsWithoutFiles.push(
        new ProofOfFund({
          typeId: typeId,
          transactionDate: model.successionDate,
          transactionAmount: model.successionAmount
        })
      );
    }
  }

  private addEmptyEstateSaleFile(proofsWithoutFiles: ProofOfFund[], model: MyFinancialFormModel, isNew: boolean) {
    const typeId = collections.proofOfFundDocuments.estateSale.typeId;
    const array = this.userData.proofOfFunds.filter((item: ProofOfFund) => {
      return item.typeId === typeId;
    });

    if (array?.length > 0 && !isNew) {
      proofsWithoutFiles.push(
        new ProofOfFund({
          uniqueId: array[0].uniqueId,
          typeId: typeId,
          transactionDate: model.estateSaleDate,
          transactionAmount: model.estateSaleAmount
        })
      );
    } else {
      proofsWithoutFiles.push(
        new ProofOfFund({
          typeId: typeId,
          transactionDate: model.estateSaleDate,
          transactionAmount: model.estateSaleAmount
        })
      );
    }
  }

  private addEmptyCashflowFile(proofsWithoutFiles: ProofOfFund[], model: MyFinancialFormModel, isNew: boolean) {
    const typeId = collections.proofOfFundDocuments.cashflow.typeId;
    const array = this.userData.proofOfFunds.filter((item: ProofOfFund) => {
      return item.typeId === typeId;
    });

    if (array?.length > 0 && !isNew) {
      proofsWithoutFiles.push(
        new ProofOfFund({
          uniqueId: array[0].uniqueId,
          typeId: typeId,
          transactionDate: null,
          transactionAmount: model.cashflowAmount
        })
      );
    } else {
      proofsWithoutFiles.push(
        new ProofOfFund({
          typeId: typeId,
          transactionDate: null,
          transactionAmount: model.cashflowAmount
        })
      );
    }
  }

  private addEmptyOtherFile(proofsWithoutFiles: ProofOfFund[], model: MyFinancialFormModel, isNew: boolean) {
    const typeId = collections.proofOfFundDocuments.other.typeId;
    const array = this.userData.proofOfFunds.filter((item: ProofOfFund) => {
      return item.typeId === typeId;
    });

    if (array?.length > 0 && !isNew) {
      proofsWithoutFiles.push(
        new ProofOfFund({
          uniqueId: array[0].uniqueId,
          typeId: typeId,
          transactionDate: model.otherDate,
          transactionAmount: model.otherAmount
        })
      );
    } else {
      proofsWithoutFiles.push(
        new ProofOfFund({
          typeId: typeId,
          transactionDate: model.otherDate,
          transactionAmount: model.otherAmount
        })
      );
    }
  }

  public async updateBicIbanRum(bic: string, iban: string, rum: string) {
    if (!this.userId) {
      return null;
    }

    const profileData = this.getProfileData();

    profileData.bic = bic;
    profileData.rum = rum;
    profileData.iban = iban;

    this.userData = await this.saveProfile(profileData);

    return this.userData.uniqueId;
  }

  public async updateMyFinancialModel(model: MyFinancialFormModel, bsModalService: BsModalService, labelTextPipe: LabelTextPipe): Promise<any> {
    if (!this.userId) {
      return null;
    }

    let uploadDocsIssue: boolean;
    const profileData = this.getProfileData();
    const profile = getUpdatedByMyFinancialModelProfileModel(model, profileData);
    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    const promises: Promise<ProofOfFund>[] = [];
    const proofsWithoutFiles: ProofOfFund[] = [];

    if (model.surrenderLifeInsurancePolicyFiles !== undefined && model.surrenderLifeInsurancePolicyFiles.length > 0) {
      model.surrenderLifeInsurancePolicyFiles.forEach(async (file: any) => {
        if (file) {
          promises.push(
            new Promise<any>(resolve => {
              this.getPreparedToSaveProofFile(
                file,
                collections.proofOfFundDocuments.surrenderLifeInsurancePolicy.typeId,
                model.surrenderLifeInsurancePolicyDate,
                model.surrenderLifeInsurancePolicyAmount,
                this.userData,
                resolve
              );
            })
          );
        } else {
          this.addEmptySurrenderLifeInsurancePolicyFile(proofsWithoutFiles, model, false);
        }
      });
    } else if (model.surrenderLifeInsurancePolicyAmount) {
      this.addEmptySurrenderLifeInsurancePolicyFile(proofsWithoutFiles, model, true);
    }

    if (model.savingsFiles !== undefined && model.savingsFiles.length > 0) {
      model.savingsFiles.forEach(async (file: any) => {
        if (file) {
          promises.push(
            new Promise<any>(resolve => {
              this.getPreparedToSaveProofFile(file, collections.proofOfFundDocuments.savings.typeId, model.savingsDate, model.savingsAmount, this.userData, resolve);
            })
          );
        } else {
          this.addEmptySavingsFile(proofsWithoutFiles, model, false);
        }
      });
    } else if (model.savingsAmount) {
      this.addEmptySavingsFile(proofsWithoutFiles, model, true);
    }

    if (model.successionFiles !== undefined && model.successionFiles.length > 0) {
      model.successionFiles.forEach(async (file: any) => {
        if (file) {
          promises.push(
            new Promise<any>(resolve => {
              this.getPreparedToSaveProofFile(file, collections.proofOfFundDocuments.succession.typeId, model.successionDate, model.successionAmount, this.userData, resolve);
            })
          );
        } else {
          this.addEmptySuccessionFile(proofsWithoutFiles, model, false);
        }
      });
    } else if (model.successionAmount) {
      this.addEmptySuccessionFile(proofsWithoutFiles, model, true);
    }

    if (model.estateSaleFiles !== undefined && model.estateSaleFiles.length > 0) {
      model.estateSaleFiles.forEach(async (file: any) => {
        if (file) {
          promises.push(
            new Promise<any>(resolve => {
              this.getPreparedToSaveProofFile(file, collections.proofOfFundDocuments.estateSale.typeId, model.estateSaleDate, model.estateSaleAmount, this.userData, resolve);
            })
          );
        } else {
          this.addEmptyEstateSaleFile(proofsWithoutFiles, model, false);
        }
      });
    } else if (model.estateSaleAmount) {
      this.addEmptyEstateSaleFile(proofsWithoutFiles, model, true);
    }

    if (model.cashflowFiles !== undefined && model.cashflowFiles.length > 0) {
      model.cashflowFiles.forEach(async (file: any) => {
        if (file) {
          promises.push(
            new Promise<any>(resolve => {
              this.getPreparedToSaveProofFile(file, collections.proofOfFundDocuments.cashflow.typeId, null, model.cashflowAmount, this.userData, resolve);
            })
          );
        } else {
          this.addEmptyCashflowFile(proofsWithoutFiles, model, false);
        }
      });
    } else if (model.cashflowAmount) {
      this.addEmptyCashflowFile(proofsWithoutFiles, model, true);
    }

    if (model.otherFiles !== undefined && model.otherFiles.length > 0) {
      model.otherFiles.forEach(async (file: any) => {
        if (file) {
          promises.push(
            new Promise<any>(resolve => {
              this.getPreparedToSaveProofFile(file, collections.proofOfFundDocuments.other.typeId, model.otherDate, model.otherAmount, this.userData, resolve);
            })
          );
        } else {
          this.addEmptyOtherFile(proofsWithoutFiles, model, false);
        }
      });
    } else if (model.otherAmount) {
      this.addEmptyOtherFile(proofsWithoutFiles, model, true);
    }

    return await Promise.all(promises).then(async docsToBeUpload => {
      const docsToBeUploadFiltered = docsToBeUpload.filter((doc: ProofOfFund) => {
        if (doc.uniqueId) {
          delete doc.file.base64Content;
        }
        return (doc.uniqueId === undefined && doc.file?.base64Content !== undefined) || (doc.uniqueId !== undefined && doc.file?.base64Content === undefined) || doc.uniqueId;
      });

      const docsToBeIgnored = docsToBeUpload.filter((doc: ProfileDocument) => {
        return (doc.uniqueId === undefined && doc.file?.base64Content === undefined) || (doc.uniqueId === undefined && doc.file?.base64Content === '');
      });

      this.extendByUniqIdDocsToBeUpload(profileData, docsToBeUploadFiltered);

      const arrayOfDocs = proofsWithoutFiles.concat(docsToBeUploadFiltered);

      if (docsToBeIgnored?.length > 0) {
        const modal = bsModalService.show(AlertModalComponent, {
          initialState: {
            type: modalTypes.error,
            title: `${labelTextPipe.transform('Common.DocsIgnoredModalTitle')} :`,
            body: this.prepareIgnoredFilesModalBody(docsToBeIgnored)
          },
          class: 'modal-lg'
        });

        modal.content.onClose.subscribe(async () => {});
        return null;
      } else {
        uploadDocsIssue = !(await this.updateProofOfFundsDocuments(this.userId, arrayOfDocs));
        return uploadDocsIssue ? null : this.userId;
      }
    });
  }

  extendByUniqIdDocsToBeUpload(profileData: Profile, proofs: ProofOfFund[]): void {
    proofs.forEach((proof: ProofOfFund) => {
      const savedProof = profileData.proofOfFunds.find((savedProof: ProofOfFund) => {
        return savedProof.typeId === proof.typeId && savedProof.file?.fileName === proof.file?.fileName;
      });

      if (savedProof) {
        proof.file.uniqueId = savedProof.file?.uniqueId;
      }
    });
  }

  public async updateAdvertisementSourceDataModel(model: AdvertisementSourceDataModel, signatureStep: boolean = false) {
    if (!this.userId) {
      return null;
    }

    const profile = this.getProfileData();

    if (!profile.advertisementSource) {
      profile.advertisementSource = new AdvertisementSource();
    }

    profile.advertisementSource.advertisementSourceCode = model.advertisementSourceCode;
    profile.advertisementSource.sponsorNumber = model.sponsorNumber ? model.sponsorNumber + '' : model.sponsorNumber;
    profile.advertisementSource.memberNumber = model.memberNumber ? model.memberNumber + '' : model.memberNumber;
    profile.advertisementSource.firstName = model.firstName;
    profile.advertisementSource.lastName = model.lastName;
    profile.advertisementSource.promoCode = model.promoCode;
    profile.advertisementSource.partnerCode = model.partnerCode;
    profile.advertisementSource.mifAdvisorCode = model.mifAdvisorCode ? model.mifAdvisorCode + '' : model.mifAdvisorCode;
    profile.profileStepCode = signatureStep ? ProfileStepCode.Signature : ProfileStepCode.MembershipCheck;

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    return this.userId;
  }

  public async updatePromoCodeModel(model: AdvertisementSourceDataModel) {
    if (!this.userId) {
      return null;
    }

    const profile = this.getProfileData();

    if (!profile.advertisementSource) {
      profile.advertisementSource = new AdvertisementSource();
    }

    profile.advertisementSource.promoCode = model.promoCode;

    profile.profileStepCode = ProfileStepCode.Signature;

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    return this.userId;
  }

  public updateAdvertisementSourceDataModelWithoutSaving(profile: Profile, model: AdvertisementSourceDataModel): Profile {
    if (!this.userId) {
      return null;
    }

    if (!profile.advertisementSource) {
      profile.advertisementSource = new AdvertisementSource();
    }

    const profileData = Object.assign({}, profile);

    profileData.advertisementSource.advertisementSourceCode = model.advertisementSourceCode;
    profileData.advertisementSource.sponsorNumber = model.sponsorNumber ? model.sponsorNumber + '' : model.sponsorNumber;
    profileData.advertisementSource.memberNumber = model.memberNumber ? model.memberNumber + '' : model.memberNumber;
    profileData.advertisementSource.firstName = model.firstName;
    profileData.advertisementSource.lastName = model.lastName;
    profileData.advertisementSource.promoCode = model.promoCode;
    profileData.advertisementSource.partnerCode = model.partnerCode;
    profileData.advertisementSource.mifAdvisorCode = model.mifAdvisorCode ? model.mifAdvisorCode + '' : model.mifAdvisorCode;
    profileData.profileStepCode = ProfileStepCode.MembershipCheck;

    return profileData;
  }

  public updatePromoCodeModelWithoutSaving(profile: Profile, model: AdvertisementSourceDataModel): Profile {
    if (!this.userId) {
      return null;
    }

    if (!profile.advertisementSource) {
      profile.advertisementSource = new AdvertisementSource();
    }

    const profileData = Object.assign({}, profile);

    profileData.advertisementSource.promoCode = model.promoCode;
    profileData.profileStepCode = ProfileStepCode.Signature;

    return profileData;
  }

  public async updateUTMData(utm: string) {
    if (!this.userId) {
      return null;
    }

    const profile = this.getProfileData();

    if (!profile.advertisementSource) {
      profile.advertisementSource = new AdvertisementSource();
    }

    profile.advertisementSource.utm = utm;

    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    return this.userId;
  }

  getPreparedToSaveProofFile(file: any, typeId: number, transactionDate: Date, transactionAmount: number, userData: Profile, resolve: any) {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function () {
      const text = reader.result as string;
      const base64Content = text.split('base64,')[1];
      const uniqueId = getProofFileUniqueId(file, userData, typeId);

      resolve(
        new ProofOfFund({
          uniqueId,
          typeId,
          transactionDate,
          transactionAmount,
          file: new File({ fileName: file.name, base64Content: base64Content })
        })
      );
    };
    reader.onerror = function (error) {};
  }

  getPreparedToSaveFile(file: any, typeId: number, documentTypeCode: number, userData: Profile, resolve: any) {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function () {
      const text = reader.result as string;
      const base64Content = text.split('base64,')[1];
      const uniqueId = getFileUniqueId(file, userData, typeId);

      resolve(
        new ProfileDocument({
          uniqueId,
          typeId,
          documentTypeCode,
          file: new File({ fileName: file.name, base64Content: base64Content })
        })
      );
    };
    reader.onerror = function (error) {};
  }

  public getFondsModel(): ProfileFund[] {
    return getProfileFondsModel(this.userData);
  }

  public async updateMyBankDetailsModel(model: MyBankDetailsDataModel, bsModalService: BsModalService, labelTextPipe: LabelTextPipe): Promise<any> {
    if (!this.userId) {
      return null;
    }

    let uploadDocsIssue: boolean;
    const profileData = this.getProfileData();
    const profile = getUpdatedByMyBankDetailsDataModelProfileModel(model, profileData);
    this.userData = await this.saveProfile(profile);
    this.userId = this.userData.uniqueId;

    const promises: Promise<ProfileDocument>[] = [];

    if (model.ribFiles) {
      model.ribFiles.forEach(async (file: any) => {
        promises.push(
          new Promise<any>(resolve => {
            this.getPreparedToSaveFile(file, collections.profileDocuments.ribDocument.typeId, collections.bancDetailsDocumentTypes.find(d => d.name === 'RIB').id, this.userData, resolve);
          })
        );
      });
    }

    return await Promise.all(promises).then(async profileDocuments => {
      const docsToBeUpload = profileDocuments.concat(
        this.userData.profileDocuments.filter((item: ProfileDocument) => {
          return item.typeId !== collections.profileDocuments.ribDocument.typeId;
        })
      );

      const docsToBeUploadFiltered = docsToBeUpload.filter((doc: ProfileDocument) => {
        if (doc.uniqueId) {
          delete doc.file.base64Content;
        }
        return (doc.uniqueId === undefined && doc.file?.base64Content !== undefined) || (doc.uniqueId !== undefined && doc.file?.base64Content === undefined) || doc.uniqueId;
      });

      const docsToBeIgnored = docsToBeUpload.filter((doc: ProfileDocument) => {
        return (doc.uniqueId === undefined && doc.file?.base64Content === undefined) || (doc.uniqueId === undefined && doc.file?.base64Content === '');
      });

      if (docsToBeIgnored?.length > 0) {
        const modal = bsModalService.show(AlertModalComponent, {
          initialState: {
            type: modalTypes.error,
            title: `${labelTextPipe.transform('Common.DocsIgnoredModalTitle')} :`,
            body: this.prepareIgnoredFilesModalBody(docsToBeIgnored)
          },
          class: 'modal-lg'
        });

        modal.content.onClose.subscribe(async () => {});
        return null;
      } else {
        uploadDocsIssue = !(await this.uploadDocs(docsToBeUploadFiltered));
        return uploadDocsIssue ? null : this.userId;
      }
    });
  }

  public getAdvertisementSourceDataModel(): AdvertisementSourceDataModel {
    return getAdvertisementSourceDataModel(this.userData);
  }

  private getProfileData(): Profile {
    let profileData = this.userData;

    if (!profileData) {
      profileData = {
        person: {},
        contact: {},
        profileQuestions: [],
        beneficiaries: [],
        profileFunds: []
      } as Profile;
    }

    return profileData;
  }
}

function getFileUniqueId(file: any, userData: Profile, typeId: number): string {
  let id;

  if (file.size > 0) {
    // new file, so we need to upload as new one
    return undefined;
  }

  userData.profileDocuments.forEach((doc: ProfileDocument) => {
    if (doc.file.fileName === file.name && doc.typeId === typeId) {
      id = doc.uniqueId;
    }
  });

  return id;
}

function getProofFileUniqueId(file: any, userData: Profile, typeId: number): string {
  let id;

  userData.proofOfFunds.forEach((doc: ProofOfFund) => {
    if (doc.file?.fileName === file?.name && doc.typeId === typeId) {
      id = doc.uniqueId;
    }
  });

  return id;
}
