import { constants } from '@constants/constants';
import { EuroPipe } from '@pipes/euro.pipe';
import { NumberFormControl } from '@shared/form/number-form-control/number-form-control';
import { NameWithDigitsFormControl } from '@shared/form/name-with-digits-form-control/name-with-digits-form-control';
import { collections } from '@constants/collections';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { ProgressBarService } from '@services/progress-bar.service';
import {
  Document3,
  Fonds,
  GetFraisVersementRequest,
  MandatGestion2,
  Pays,
  Produit3,
  Question,
  Recommandation,
  Informations,
  Regle,
  Theme,
  Client,
  Motifs,
  CSP,
  Professions,
  Poche,
  GetReglesMetierProduitRequest,
  GetPolicesResponse
} from '@webapi/MIF.Subscription.Parrot';
import { WsCrmQuestionnaireService } from '@webapi/services/ws-crm-questionnaire.service';
import {
  getBeneficiaryClause,
  getBeneficiaryClauseText,
  getBeneficiaryGpaClauseText,
  getFundPerformance,
  getReferencelibelleLongById,
  getNumericListQuestions,
  getProfileTypeData,
  openFileInNewTab,
  getCountryById,
  getFirstIdentificationDocumentTypeName,
  openFileByExtentionInNewTab,
  getAfterRepartitionText,
  isUserProfileDistributionEqualsRecommendet,
  getGfdBeneficiaryClauseText,
  getIdOfMondatGestionByName,
  getReferencelibelleById,
  calculateScore,
  getOfferFormatedGeneralConditionsForPdf,
  roundValue,
  isCems,
  isGpa,
  isGfd,
  isPeri,
  isCims,
  getPrimaryBeneficiaryText,
  isQuestion12AnsweredYes,
  question12YesAnswerExtraText,
  getCurrentProductName,
  getCurrentProductType,
  cleanObj,
  scrollToSelector
} from '../shared/extensions/extensions';
import { PaymentAmountLimits, WsReferentielMetierService } from '@webapi/services/ws-referentiel-metier.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertModalComponent, modalTypes } from '@components/modals/alert-modal/alert-modal.component';
import { LabelsDataService } from '@services/labels-data.service';
import { FormGroup, FormArray } from '@angular/forms';
import { CustomerService } from '@webapi/services/customer.service';
import { Router } from '@angular/router';
import { AdvertisementSource, GpaConfiguration, Offer, Profile, ProfileDocument, ProofOfFund, Questionnaire, SepaMandate, Summary } from '@webapi/MIF.Subscription.WebApi';
import { WsPolicesService } from '@webapi/services/ws-polices.service';
import { WsCRMClientService } from '@webapi/services/ws-crmclient.service';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { BeneficiareFormModelWithStatus } from '@my-beneficiaries/clause-nominative/clause-nominative.component';
import { BeneficiareLibreFormModelWithStatus } from '@my-beneficiaries/clause-libre/clause-libre.component';
import { SettingsService } from '@webapi/services/settings.service';
import { ProfileStepName } from '@models/profile-step-name';
import { SubscriptionHandler } from '@components/subscriptionHandler';
import { PocheEnum } from '@models/poche';
import { DocsModalComponent } from '@components/modals/docs-modal/docs-modal.component';
import { LabelTextPipe } from '@pipes/label-text.pipe';
import '@extensions/fonds.extensions';
import { WsGetPolices } from '@webapi/services/ws-get-polices.service';
import { checklIsSpecialGpaOfferAvailable } from '@shared/extensions/gpa-offer.extentions';
import { AdvertisementSourceDataModel, FileLink, GpaSubscriptionModel, Sources, TableItem, generateTableData, getHowDidYouHearMifText, isShowSEPA } from './signature.extentions';
import { MifadvisorCodeFormControl } from '@shared/form/mif-advisor-code-form-control/mif-advisor-code-form-control';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit, OnDestroy {
  @ViewChild('pdfContent', { static: false }) pdfContent: ElementRef;
  manualOverrideOffer: EventEmitter<Offer> = new EventEmitter();

  subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
  ProfileStepName = ProfileStepName;
  faCheck: any = faCheck;
  locationOrigin = window.location.origin;
  dataFetched = false;
  questions: any;
  questionsListHtml: string;
  allFunds: Fonds[];
  selectedFunds: Fonds[] = [];
  getOfferFormatedGeneralConditionsForPdf = getOfferFormatedGeneralConditionsForPdf;
  getFundPerformance = getFundPerformance;
  getReferencelibelleLongById = getReferencelibelleLongById;
  getReferencelibelleById = getReferencelibelleById;
  getCountryById = getCountryById;
  currentProductName = getCurrentProductName();
  moment = moment;
  momentTz = momentTz;
  constants = constants;
  identifiantCreancierSEPA: string;
  fundsRules: Produit3[];
  finalAgreeDocs: Document3[];
  finalAgreeDocsContract: string;
  mifForm: FormGroup;
  finalAgree = false;
  finalAgreeContract = false;
  politicallyPersonTypes: Motifs[];

  subscribeGpaPer1Euro = false;
  subscribeGpaPer1EuroDocsLoaded = false;
  gpaContractAmount: number;
  gpaContractAmountPerMonth: number;
  gpaSubscriptionUserTypes: number[];
  gpaSubscriptionGarantiX2x3 = true;
  gpaSubscriptionHealthQuestionsValid: boolean;
  gpaSubscriptionClauseNominativeValid: boolean;
  gpaSubscriptionClauseLibreTextValid: boolean;
  reuseBeneficiaries: boolean;
  gpaBeneficiareType: number;
  clauseNominative: boolean;
  clauseStandard: boolean;
  clauseLibre: boolean;
  gpaSubscriptionClauseNominativeModel: FormArray;
  gpaSubscriptionClauseNominativeIsEqualParts: boolean;
  gpaSubscriptionClauseLibreText: string;

  nomCommercialRuleName = constants.nomCommercialRuleName;
  regleCode = 'FOND0017';
  fundInEuroRules: Fonds;
  fundInEuroRegle: Regle;
  euroFondPercent: number;
  initialPaymentCombined: number;
  initialPaymentWithoutPercents: number;
  tableItems: TableItem[];
  rum: string;
  iban: string;
  source: any;
  sources: any = Sources;
  clientProfile: Profile;
  clientProfileForViewOffer: Profile;
  selectedProfileType: any;
  recomendedProfileType: any;
  score = 0;
  profileThemes: Theme[] = [];
  profileQuestions: Question[] = [];
  transferableContracts: any;
  recommandation: Recommandation;
  paymentsText: string;
  selectedMandatGestion: MandatGestion2;
  scheduledPaymentsAmountLimits: PaymentAmountLimits;
  countries: Pays[];
  nationalities: Informations[];
  civilities: Informations[];
  familySituations: Informations[];
  matrimonialRegimes: Informations[];
  firstIdentificationDocuments: FileLink[];
  secondIdentificationDocuments: FileLink[];
  addressConfirmationDocuments: FileLink[];
  ribDocuments: FileLink[];
  firstIdentificationDocumentType: string;
  netMonthlyIncomesPersonal: Informations[];
  netMonthlyIncomes: Informations[];
  professionalSituations: Informations[];
  socioProfessionalCategories: Informations[];
  industries: Informations[];
  originsOfWealth: Informations[];
  originOfContractFundsItems: Informations[];
  originOfWealthOtherId: any;
  originOfContractFundsOtherId: any;
  proofOfFundsAssets: any[];
  proofOfFundsAssetsHtml: string;
  proofOfWealthAssets: any[];
  proofOfWealthAssetsHtml: string;
  politicallyPersonText: string;
  surrenderLifeInsuranceHtml: string;
  savingsHtml: string;
  successionHtml: string;
  estateSaleHtml: string;
  cashflowHtml: string;
  otherHtml: string;
  finalAgreeOpened: number[] = [];
  finalAgreeContractsOpened: number[] = [];
  manualDistributionEqualsRecommendet = false;
  isCems = isCems();
  isCims = isCims();
  isGpa = isGpa();
  isGfd = isGfd();
  isPeri = isPeri();

  isProspect: boolean;
  isCustomerWithoutTransferContractCode: boolean;
  isCustomerWithTransferContractCode: boolean;
  euroFundFee = 0;
  gpaConfiguration: GpaConfiguration;
  summaryConfiguration: Summary;
  sepaMandateConfiguration: SepaMandate;
  faInfoCircle = faInfoCircle;
  professionsCollection: Professions[] = [];
  questionnaireConfiguration: Questionnaire;
  offer: Offer;
  prevMifFormData: any;
  promoCodeControl: NameWithDigitsFormControl;
  mifAdvisorCodeControl: MifadvisorCodeFormControl;
  modalItem: any;
  offers: Offer[];
  clientProfileAdvertisementSourceOrig: AdvertisementSource;
  polices: GetPolicesResponse;
  advertisementSourceDataModel: AdvertisementSourceDataModel;

  constructor(
    private progressBarService: ProgressBarService,
    private wsCrmQuestionnaireService: WsCrmQuestionnaireService,
    private labelsDataService: LabelsDataService,
    private wsReferentielMetierService: WsReferentielMetierService,
    private bsModalService: BsModalService,
    private customerService: CustomerService,
    private wsPolicesService: WsPolicesService,
    private wsCRMClientService: WsCRMClientService,
    private router: Router,
    private euroPipe: EuroPipe,
    private webApi: Client,
    private settingsService: SettingsService,
    private labelTextPipe: LabelTextPipe,
    private wsGetPolices: WsGetPolices,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.subscriptionHandler.unsubscribeAll();
  }

  async ngOnInit(): Promise<void> {
    this.progressBarService.sendStep(constants.steps.signature.initialStep);
    await this.fetchInitData();
    if (this.isCems || this.isPeri || this.isCims) {
      await this.getRecommendation();
    }
    this.initNewFormGroup();
    this.initControls();
    this.initSubscriptions();
    await this.restoreAnswersOnForm();
    await this.fetchDocuments();
    this.dataFetched = true;
  }

  async fetchDocuments(): Promise<any> {
    const selectedFundsIds = this.selectedFunds?.map(f => f.id);

    if (this.isCems) {
      this.finalAgreeDocs = await this.wsReferentielMetierService.getCemsDocumentsProduitSignatureSouscriptionEnLigne(selectedFundsIds);
    }

    if (this.isPeri) {
      this.finalAgreeDocs = await this.wsReferentielMetierService.getPeriDocumentsProduitSignatureSouscriptionEnLigne(selectedFundsIds);
    }

    if (this.isCims) {
      this.finalAgreeDocs = await this.wsReferentielMetierService.getCimsDocumentsProduitSignatureSouscriptionEnLigne(selectedFundsIds);
    }

    if (this.isGpa) {
      this.finalAgreeDocs = await this.wsReferentielMetierService.getGpaDocumentsProduitSignatureSouscriptionEnLigne();
    }

    if (this.isGfd) {
      this.finalAgreeDocs = await this.wsReferentielMetierService.getGfdDocumentsProduitSignatureSouscriptionEnLigne();
    }
  }

  async checkAndExtendFinalAgreeDocsByExtraGpaDocs(): Promise<void> {
    if (this.isSpecialGpaOfferAvailable && !!this.clientProfile.gpaProfile) {
      const extraGpaDocs = await this.wsReferentielMetierService.getExtraDocumentsForGpaSubscriptionOffer();
      this.finalAgreeDocs = this.finalAgreeDocs.concat(extraGpaDocs ?? []);
    }
  }

  async fetchInitData(): Promise<void> {
    this.questionnaireConfiguration = await this.settingsService.getQuestionnaireConfiguration();
    this.summaryConfiguration = await this.settingsService.getSummaryConfiguration();
    this.sepaMandateConfiguration = await this.settingsService.getSepaMandateConfiguration();
    this.politicallyPersonTypes = await this.wsCRMClientService.getPoliticallyPersonTypes();

    this.identifiantCreancierSEPA = this.sepaMandateConfiguration.creditorIdentifier;

    if (this.isCems || this.isPeri || this.isCims) {
      this.gpaConfiguration = await this.settingsService.getGpaConfiguration();
      this.gpaContractAmount = this.gpaConfiguration.gpaGuaranteedCapital;
      this.gpaContractAmountPerMonth = this.gpaConfiguration.gpaCost;
      this.gpaSubscriptionGarantiX2x3 = this.gpaConfiguration.hasMultipliedCoverage;
      this.gpaSubscriptionUserTypes = this.gpaConfiguration.userTypes;

      this.questions = await this.wsCrmQuestionnaireService.getQuestions();
      this.profileThemes = this.questions.themes;
      this.profileThemes.forEach((element: Theme) => {
        element.questions.forEach((question: Question) => {
          this.profileQuestions.push(question);
        });
      });
      this.fundsRules = await this.wsReferentielMetierService.getFundsRules();
      this.scheduledPaymentsAmountLimits = await this.wsReferentielMetierService.getScheduledPaymentAmountLimits();
      this.fundsRules.forEach((item: Fonds) => {
        if (item.nomCommercial === this.nomCommercialRuleName) {
          this.fundInEuroRules = item;
          item.typeRegle[0].regle.forEach((regle: Regle) => {
            if (regle.code?.trim() == this.regleCode) {
              this.fundInEuroRegle = regle;
            }
          });
        }
      });
    }

    this.countries = await this.wsCRMClientService.getAllCountries();
    this.nationalities = await this.wsCRMClientService.getNationalities();
    this.civilities = await this.wsCRMClientService.getCivilities();
    this.familySituations = await this.wsCRMClientService.getFamilySituations();
    this.matrimonialRegimes = await this.wsCRMClientService.getMatrimonialRegimes();
    this.netMonthlyIncomesPersonal = await this.wsCRMClientService.getNetMonthlyIncomesPersonal();
    this.netMonthlyIncomes = await this.wsCRMClientService.getNetMonthlyIncomes();
    this.professionalSituations = await this.wsCRMClientService.getProfessionalSituations();
    this.socioProfessionalCategories = await this.wsCRMClientService.getSocioProfessionalCategories();
    this.industries = await this.wsCRMClientService.getIndustries();
    this.originsOfWealth = await this.wsCRMClientService.getOriginsOfWealth();
    this.originOfWealthOtherId = await this.wsCRMClientService.getOriginsOfWealthOtherId();
    this.originOfContractFundsItems = await this.wsReferentielMetierService.getOriginOfContractFundsItems();
    this.originOfContractFundsOtherId = await this.wsReferentielMetierService.getOriginOfContractFundsOtherId();

    this.clientProfile = await this.customerService.getUserData();
    this.rum = this.clientProfile.rum;
    this.clientProfileForViewOffer = Object.assign({}, this.clientProfile);
    this.clientProfileAdvertisementSourceOrig = cleanObj(Object.assign({}, this.clientProfileForViewOffer.advertisementSource));

    this.initProfessionsCollection();
    this.initUserType();

    if (this.isCems || this.isPeri || this.isCims) {
      this.questionsListHtml = getNumericListQuestions(this.questions, this.clientProfile);
    }
  }

  get isMaried(): boolean {
    if (this.clientProfile && this.familySituations) {
      const status = this.familySituations.filter((item: Informations) => {
        return item.id === this.clientProfile.maritalStatusCode;
      });

      return status[0]?.libelleLong === constants.mariedAnswer;
    } else {
      return null;
    }
  }

  async getRecommendation(): Promise<void> {
    const profileQuestionsModel = this.customerService.getProfileQuestionsModel();
    const clientAge = moment().diff(this.clientProfile.person.dateOfBirth, 'years', false);
    const score = calculateScore(profileQuestionsModel, this.profileQuestions, clientAge, this.questionnaireConfiguration.ageScores);
    const montantVerse = this.clientProfile.initialPaymentAmount || 0; // initialPaymentAmount - it's one field for free payment amount and first payment amount
    const flagDurabilite = this.clientProfile.isQuestion12AnsweredYes(this.questions);
    const dateEstimationDepartRetraite = this.isPeri ? this.clientProfile.retirementPlan.expectedRetirementDate : null;
    const recommandationResponse = await this.wsReferentielMetierService.getRecommandation(
      score,
      !!this.clientProfile.transferContractCode,
      montantVerse,
      false,
      dateEstimationDepartRetraite,
      flagDurabilite
    );

    if (this.clientProfile.socialNumberB) {
      this.transferableContracts = await this.wsPolicesService.getContratsTransferablesFourgousRequest(this.clientProfile.socialNumberB);
    }

    if (recommandationResponse.erreurs?.length > 0) {
      this.bsModalService.show(AlertModalComponent, {
        initialState: {
          type: modalTypes.error,
          title: recommandationResponse.erreurs[0].codeErreur + ' ' + recommandationResponse.erreurs[0].libelleErreur
        },
        class: 'modal-lg'
      });
      return;
    }

    this.recommandation = recommandationResponse.recommandation;
  }

  initProfessionsCollection(): void {
    this.socioProfessionalCategories?.forEach((type: CSP) => {
      if (type.id === this.clientProfile.professionalInfo?.socialProfessionCategoryCode) {
        this.professionsCollection = type.professions;
      }
    });
  }

  initUserType(): void {
    this.isProspect = !this.clientProfile.isMember;
    this.isCustomerWithoutTransferContractCode = !this.clientProfile.transferContractCode;
    this.isCustomerWithTransferContractCode = !!this.clientProfile.transferContractCode;
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.mifForm?.valueChanges.subscribe((data: any) => {
      if (this.prevMifFormData?.promoCode !== data.promoCode) {
        // cause no need to emit the changes in case we edit promoCode
        this.prevMifFormData = Object.assign({}, data);
        return;
      }

      if (JSON.stringify(this.prevMifFormData) !== JSON.stringify(data)) {
        if (this.prevMifFormData?.promoCode && this.prevMifFormData?.promoCode !== data.promoCode) {
          this.prevMifFormData = Object.assign({}, data);
          return;
        }

        this.prevMifFormData = Object.assign({}, data);

        this.cdr.detectChanges();

        const advertisementSourceModel = this.mifForm.getRawValue() as AdvertisementSourceDataModel;
        this.clientProfileForViewOffer = this.customerService.updatePromoCodeModelWithoutSaving(this.clientProfile, advertisementSourceModel);
      }
    });

    this.subscriptionHandler.subscriptions = this.promoCodeControl.valueChanges.subscribe((promoCodeValue: string) => {
      const offerWithCode = this.offers?.find((offer: Offer) => {
        return offer.promoCode === promoCodeValue;
      });

      if (promoCodeValue === null || promoCodeValue?.trim() === '') {
        this.promoCodeControl.markAsValid();
        this.mifForm.updateValueAndValidity();
        return;
      }

      this.promoCodeControl.markValidOrNot(!!offerWithCode);
      this.mifForm.updateValueAndValidity();

      if (!!offerWithCode) {
        this.manualOverrideOffer.emit(offerWithCode);
      }
    });
  }

  initControls(): void {
    this.promoCodeControl = this.mifForm.get('promoCode') as NameWithDigitsFormControl;
    this.mifAdvisorCodeControl = this.mifForm.get('mifAdvisorCode') as MifadvisorCodeFormControl;
  }

  get getHowDidYouHearMif(): string {
    if (this.advertisementSourceDataModel) {
      return getHowDidYouHearMifText(this.advertisementSourceDataModel, this.labelTextPipe);
    } else {
      return '';
    }
  }

  get getMifAdvisorInfo(): string {
    if (this.advertisementSourceDataModel?.mifAdvisorCode) {
      return `${this.labelTextPipe.transform('SUMMARY.MifAdvisorInfo')} <span style="color: #189bd5" class="answer">${this.advertisementSourceDataModel.mifAdvisorCode}</span>. `;
    }
    return '';
  }

  get isMifFormNotEmpty(): boolean {
    return (
      !!this.advertisementSourceDataModel?.advertisementSourceCode ||
      !!this.advertisementSourceDataModel?.sponsorNumber ||
      !!this.advertisementSourceDataModel?.memberNumber ||
      !!this.advertisementSourceDataModel?.firstName ||
      !!this.advertisementSourceDataModel?.lastName ||
      !!this.advertisementSourceDataModel?.mifAdvisorCode ||
      !!this.advertisementSourceDataModel?.promoCode
    );
  }

  async restoreAnswersOnForm(): Promise<void> {
    if (this.clientProfile) {
      await this.restoreEuroFundFee();
      await this.restoreFinalAgreeDocsContract();
      this.generatePaymentsText();
      this.iban = this.clientProfile.iban;
      if (this.isCems || this.isPeri || this.isCims) {
        await this.restorePolices();
        this.selectedProfileType = getProfileTypeData(this.clientProfile.profileTypeCode);
        this.manualDistributionEqualsRecommendet = isUserProfileDistributionEqualsRecommendet(this.clientProfile, this.recommandation);
        this.restoreRecomendedProfileType();
        await this.restoreUserFundsByIds();
        this.euroFondPercent = roundValue(this.clientProfile.profileFunds.find((f: any) => f.fundId === 1)?.portion, 2);
        this.initialPaymentWithoutPercents = (this.clientProfile.initialPaymentTotalAmount() / 100) * this.euroFondPercent;
        this.tableItems = generateTableData(this.fundInEuroRegle, this.initialPaymentWithoutPercents);
        this.restoreProofOfFonds();
      }

      this.restoreSpecialGpaOfferData();
      this.restoreFiles();
      this.restoreProfileAssets();
      this.restorePoliticallyPersonText();
      this.restoreAdvertisementSourceData();
    }
  }

  async restoreEuroFundFee(): Promise<void> {
    if (this.clientProfile.profileFunds?.length > 0) {
      const euroFond = this.clientProfile.profileFunds.find((f: any) => f.fundId === constants.euroFondId);

      if (euroFond) {
        this.euroFundFee = await this.getEuroFundFees(euroFond.portion);
      }
    }
  }

  async restoreFinalAgreeDocsContract(): Promise<void> {
    if (this.clientProfile.transferContractCode) {
      this.finalAgreeDocsContract = await this.wsReferentielMetierService.getCemsDocumentsProduit(12);
    }
  }

  restoreRecomendedProfileType(): void {
    let recomendedProfileTypeId;
    if (this.recommandation.id === constants.securitaireProfileLess40KId || this.recommandation.id === constants.securitaireProfileMore40KId) {
      recomendedProfileTypeId = this.recommandation.id;
    } else {
      recomendedProfileTypeId = getIdOfMondatGestionByName(this.recommandation.profil.libelle);
    }
    this.recomendedProfileType = getProfileTypeData(recomendedProfileTypeId);
  }

  async restorePolices() {
    if (this.clientProfile.isMember && this.clientProfile.socialNumberB) {
      this.polices = await this.wsGetPolices.getPolices(parseInt(this.clientProfile.socialNumberB, 10));
    }
  }

  restoreAdvertisementSourceData() {
    const model = this.customerService.getAdvertisementSourceDataModel();
    this.advertisementSourceDataModel = model;

    if (model.promoCode) {
      this.promoCodeControl.patchValue(model.promoCode);
      setTimeout(() => {
        this.promoCodeControl.markAsTouched();
        this.promoCodeControl.markAsDirty();
        this.promoCodeControl.updateValueAndValidity();
      }, 1);
    }

    if (model.mifAdvisorCode) {
      this.mifAdvisorCodeControl.patchValue(model.mifAdvisorCode);
    }
  }

  restoreSpecialGpaOfferData(): void {
    if (this.isSpecialGpaOfferAvailable) {
      if (!this.clientProfile.gpaProfile || this.clientProfile.gpaProfile === null || this.clientProfile.gpaProfile?.isMultipliedCoverage === false) {
        return;
      } else {
        this.subscribeGpaPer1Euro = true;
        this.subscribeGpaPer1EuroClick(true, true);

        const tempReuseData = localStorage.getItem(constants.localStorageKeys.tempReuseBeneficiaries);
        const tempGpaBeneficiareTypeData = localStorage.getItem(constants.localStorageKeys.tempGpaBeneficiareType);
        let tempReuseValue;
        let tempGpaBeneficiareTypeValue;

        if (tempReuseData && this.subscribeGpaPer1Euro) {
          tempReuseValue = JSON.parse(tempReuseData);
        }

        if (tempGpaBeneficiareTypeData && this.subscribeGpaPer1Euro) {
          tempGpaBeneficiareTypeValue = JSON.parse(tempGpaBeneficiareTypeData);
        }

        if (this.clientProfile.gpaProfile.beneficiaryClauseCode !== null && this.clientProfile.gpaProfile.beneficiaryClauseCode !== undefined) {
          this.reuseBeneficiariesChanged(false, true);
          this.switchGpaBeneficiareType(this.clientProfile.gpaProfile.beneficiaryClauseCode);
        } else {
          this.reuseBeneficiariesChanged(true, true);
        }

        if (tempReuseValue !== undefined) {
          this.reuseBeneficiaries = tempReuseValue;
          if (tempReuseValue === false && tempGpaBeneficiareTypeValue !== undefined) {
            this.switchGpaBeneficiareType(tempGpaBeneficiareTypeValue);
          }
        }
      }
    }
  }

  restoreProofOfFonds(): void {
    if (this.clientProfile.proofOfFunds?.length > 0) {
      this.clientProfile.proofOfFunds.forEach((doc: ProofOfFund) => {
        switch (doc.typeId) {
          case collections.proofOfFundDocuments.surrenderLifeInsurancePolicy.typeId: {
            this.surrenderLifeInsuranceHtml = `${this.euroPipe.transform(doc.transactionAmount)} ${this.labelTextPipe.transform('SUMMARY.RestoreProofOfFondsText')} ${moment
              .utc(doc.transactionDate)
              .format(constants.dateInputFormat)}`;
            break;
          }
          case collections.proofOfFundDocuments.savings.typeId: {
            this.savingsHtml = `${this.euroPipe.transform(doc.transactionAmount)} ${this.labelTextPipe.transform('SUMMARY.RestoreProofOfFondsText')} ${moment
              .utc(doc.transactionDate)
              .format(constants.dateInputFormat)}`;
            break;
          }
          case collections.proofOfFundDocuments.succession.typeId: {
            this.successionHtml = `${this.euroPipe.transform(doc.transactionAmount)} ${this.labelTextPipe.transform('SUMMARY.RestoreProofOfFondsText')} ${moment
              .utc(doc.transactionDate)
              .format(constants.dateInputFormat)}`;
            break;
          }
          case collections.proofOfFundDocuments.estateSale.typeId: {
            this.estateSaleHtml = `${this.euroPipe.transform(doc.transactionAmount)} ${this.labelTextPipe.transform('SUMMARY.RestoreProofOfFondsText')} ${moment
              .utc(doc.transactionDate)
              .format(constants.dateInputFormat)}`;
            break;
          }
          case collections.proofOfFundDocuments.cashflow.typeId: {
            this.cashflowHtml = `${this.euroPipe.transform(doc.transactionAmount)}`;
            break;
          }
          case collections.proofOfFundDocuments.other.typeId: {
            this.otherHtml = `${this.euroPipe.transform(doc.transactionAmount)} ${this.labelTextPipe.transform('SUMMARY.RestoreProofOfFondsText')} ${moment
              .utc(doc.transactionDate)
              .format(constants.dateInputFormat)}`;
            break;
          }
        }
      });
    }
  }

  restorePoliticallyPersonText(): void {
    if (this.clientProfile.politicalStatusCode === constants.yesAnswerId) {
      let selectedCommonTypeName = '';
      let selectedTypeName = '';

      this.politicallyPersonTypes.forEach((commonType: Motifs) => {
        commonType.sousmotifs.forEach(type => {
          if (type.id === this.clientProfile.politicalStatusValue) {
            selectedCommonTypeName = commonType.libelle;
            selectedTypeName = type.libelle;
          }
        });
      });

      this.politicallyPersonText = `${this.labelTextPipe.transform('SUMMARY.DetailsSectionPoliticallySection')} ${selectedCommonTypeName}, ${selectedTypeName}.`;
    } else {
      this.politicallyPersonText = `${this.labelTextPipe.transform('SUMMARY.DetailsSectionPoliticallySection1')}`;
    }
  }

  restoreProfileAssets(): void {
    if (this.clientProfile.profileAssets) {
      if (this.clientProfile.profileAssets.proofOfWealthAssets?.length > 0) {
        this.proofOfWealthAssets = [];

        this.originsOfWealth.forEach((item: Informations) => {
          this.clientProfile.profileAssets.proofOfWealthAssets.forEach((savedProofId: number) => {
            if (savedProofId !== this.originOfWealthOtherId && savedProofId === item.id) {
              this.proofOfWealthAssets.push(item.libelleLong);
            }
          });
        });

        if (this.clientProfile.profileAssets.proofOfWealthDescription) {
          this.proofOfWealthAssets.push(this.clientProfile.profileAssets.proofOfWealthDescription);
        }

        this.proofOfWealthAssetsHtml = this.proofOfWealthAssets.join(', ').toLowerCase();
      }
      if (this.clientProfile.profileAssets.proofOfFundsAssets?.length > 0) {
        this.proofOfFundsAssets = [];

        this.originOfContractFundsItems.forEach((item: Informations) => {
          this.clientProfile.profileAssets.proofOfFundsAssets.forEach((savedProofId: number) => {
            if (savedProofId !== this.originOfContractFundsOtherId && savedProofId === item.id) {
              this.proofOfFundsAssets.push(item.libelleLong);
            }
          });
        });

        if (this.clientProfile.profileAssets.proofOfFundsDescription) {
          this.proofOfFundsAssets.push(this.clientProfile.profileAssets.proofOfFundsDescription);
        }

        this.proofOfFundsAssetsHtml = this.proofOfFundsAssets.join(', ').toLowerCase();
      }
    }
  }

  restoreFiles(): void {
    if (this.clientProfile.profileDocuments?.length > 0) {
      this.clientProfile.profileDocuments.forEach((item: ProfileDocument) => {
        const file = { uniqueId: item.file.uniqueId, name: item.file.fileName } as FileLink;

        if (item.typeId === collections.profileDocuments.firstIdentificationDocument.typeId) {
          if (!this.firstIdentificationDocuments) {
            this.firstIdentificationDocuments = [];
          }

          this.firstIdentificationDocumentType = getFirstIdentificationDocumentTypeName(item.documentTypeCode);
          this.firstIdentificationDocuments.push(file);
        }
        if (item.typeId === collections.profileDocuments.addressConfirmationDocument.typeId) {
          if (!this.addressConfirmationDocuments) {
            this.addressConfirmationDocuments = [];
          }

          this.addressConfirmationDocuments.push(file);
        }
        if (item.typeId === collections.profileDocuments.ribDocument.typeId) {
          if (!this.ribDocuments) {
            this.ribDocuments = [];
          }

          this.ribDocuments.push(file);
        }
      });
    }
  }

  async getFile(file: FileLink): Promise<void> {
    const context = await this.customerService.getFile(file.uniqueId);
    await openFileByExtentionInNewTab(context.fileName, context.base64Content);
  }

  async subscribeGpaPer1EuroClick(subscribe: boolean, restoringData: boolean = false): Promise<void> {
    this.subscribeGpaPer1Euro = subscribe;
    this.subscribeGpaPer1EuroDocsLoaded = false;
    const selectedFundsIds = this.selectedFunds.map(f => f.id);

    if (subscribe) {
      if (isCems()) {
        this.finalAgreeDocs = await this.wsReferentielMetierService.getCemsDocumentsProduitSignatureSouscriptionEnLigne(selectedFundsIds);
        const extraGpaDocs = await this.wsReferentielMetierService.getExtraDocumentsForGpaSubscriptionOffer();
        this.finalAgreeDocs = this.finalAgreeDocs.concat(extraGpaDocs ?? []);
      }
      if (isCims()) {
        this.finalAgreeDocs = await this.wsReferentielMetierService.getCimsDocumentsProduitSignatureSouscriptionEnLigne(selectedFundsIds);
        const extraGpaDocs = await this.wsReferentielMetierService.getExtraDocumentsForGpaSubscriptionOffer();
        this.finalAgreeDocs = this.finalAgreeDocs.concat(extraGpaDocs ?? []);
      }
      if (isPeri()) {
        this.finalAgreeDocs = await this.wsReferentielMetierService.getPeriDocumentsProduitSignatureSouscriptionEnLigne(selectedFundsIds);
        const extraGpaDocs = await this.wsReferentielMetierService.getExtraDocumentsForGpaSubscriptionOffer();
        this.finalAgreeDocs = this.finalAgreeDocs.concat(extraGpaDocs ?? []);
      }

      this.subscribeGpaPer1EuroDocsLoaded = true;
    } else {
      this.fetchDocuments();

      if (restoringData === false) {
        this.clearTempData();
      }
    }

    this.clientProfileForViewOffer = this.customerService.updateGpaSubscriptionWithoutSaving(this.clientProfile, {
      isMultipliedCoverage: subscribe,
      isEqualParts: this.gpaSubscriptionClauseNominativeIsEqualParts
    } as GpaSubscriptionModel);

    if (restoringData === false) {
      this.customerService.updateGpaSubscription({
        isMultipliedCoverage: subscribe,
        isEqualParts: this.gpaSubscriptionClauseNominativeIsEqualParts
      } as GpaSubscriptionModel);
    }
  }

  onGpaSubscriptionHealthQuestionsChange(valid: boolean): void {
    this.gpaSubscriptionHealthQuestionsValid = valid;
  }

  onGpaSubscriptionClauseNominativeChange(event: BeneficiareFormModelWithStatus): void {
    this.gpaSubscriptionClauseNominativeValid = event.status;
    this.gpaSubscriptionClauseNominativeModel = event.beneficiaries;
    this.gpaSubscriptionClauseNominativeIsEqualParts = event.isEqualParts;
  }

  onGpaSubscriptionClauseLibreChange(event: BeneficiareLibreFormModelWithStatus): void {
    this.gpaSubscriptionClauseLibreTextValid = event.status;
    this.gpaSubscriptionClauseLibreText = event.text;
  }

  reuseBeneficiariesChanged(reuse: boolean, fromRestoreOperation: boolean = false): void {
    this.reuseBeneficiaries = reuse;

    if (fromRestoreOperation === false) {
      localStorage.setItem(constants.localStorageKeys.tempReuseBeneficiaries, JSON.stringify(reuse));
    }

    if (reuse === true && fromRestoreOperation === false) {
      localStorage.removeItem(constants.localStorageKeys.tempGpaBeneficiareType);
      localStorage.removeItem(constants.localStorageKeys.tempClauseNominativeForm);
      localStorage.removeItem(constants.localStorageKeys.tempClauseNominativeFormIsEqual);
      localStorage.removeItem(constants.localStorageKeys.tempClauseLibreForm);
    }
  }

  switchGpaBeneficiareType(code: number, withScroll: boolean = false): void {
    this.gpaBeneficiareType = code;

    if (code) {
      localStorage.setItem(constants.localStorageKeys.tempGpaBeneficiareType, code + '');
    }

    switch (code) {
      case constants.beneficiaresTypes.standard.code: {
        this.clauseStandard = true;
        this.clauseLibre = false;
        this.clauseNominative = false;
        if (withScroll) {
          setTimeout(() => {
            scrollToSelector('.clause-standart-form-anchor');
          }, 10);
        }

        break;
      }
      case constants.beneficiaresTypes.nominative.code: {
        this.clauseNominative = true;
        this.clauseLibre = false;
        this.clauseStandard = false;
        if (withScroll) {
          setTimeout(() => {
            scrollToSelector('.clause-nominative-form-anchor');
          }, 10);
        }
        break;
      }

      case constants.beneficiaresTypes.libre.code: {
        this.clauseLibre = true;
        this.clauseNominative = false;
        this.clauseStandard = false;
        if (withScroll) {
          setTimeout(() => {
            scrollToSelector('.clause-libre-form-anchor');
          }, 10);
        }
        break;
      }
    }
  }

  async getProofOfFundsFile(file: ProofOfFund): Promise<void> {
    const context = await this.customerService.getFile(file.file.uniqueId);
    await openFileByExtentionInNewTab(context.fileName, context.base64Content);
  }

  getSelectedFondText(fond: Fonds): string {
    if (fond.typeFonds.id === 1) {
      return fond.libelle;
    } else return `${fond.libelle} - ${fond.codeISIN}`;
  }

  getRepartition(fond: Fonds): number {
    return roundValue(this.clientProfile.profileFunds.find((f: any) => f.fundId === fond.id).portion, 2);
  }

  getFee(fond: Fonds): string {
    let fee = 0;

    if (fond.id === constants.euroFondId) {
      fee = this.euroFundFee;
    }

    return this.euroPipe.transform(fee);
  }

  async getEuroFundFees(fraction: number): Promise<number> {
    const amount = this.clientProfile.isScheduledPaymentsOnly() ? this.clientProfile.scheduledPaymentAmount : this.clientProfile.initialPaymentAmount;

    const taxRequest = new GetFraisVersementRequest({
      transfertPacte: !!this.clientProfile.transferContractCode,
      numeroContrat: this.clientProfile.transferContractCode ?? undefined,
      montantVerse: amount ?? 0,
      pourcentageVersementFondsEuro: Number(fraction)
    });

    const taxResponse = await this.webApi.getFraisVersement(taxRequest).toPromise();

    return taxResponse.versement.montantFraisVersementFondsEuro;
  }

  async restoreUserFundsByIds(): Promise<void> {
    let mandatFunds;

    if (this.clientProfile.profileTypeCode === constants.securitaireProfileLess40KId || this.clientProfile.profileTypeCode === constants.securitaireProfileMore40KId) {
      const pocheUCFondsRepartitions =
        this.recommandation.produitRecommande.modeGestion[0]?.poche.filter((item: Poche) => {
          return item.libelle === PocheEnum.UC;
        })[0]?.fondsRepartition ?? [];

      const pocheEurosFondsRepartitions =
        this.recommandation.produitRecommande.modeGestion[0]?.poche.filter((item: Poche) => {
          return item.libelle === PocheEnum.EURO;
        })[0]?.fondsRepartition ?? [];

      mandatFunds = pocheUCFondsRepartitions?.map(f => f?.fonds).concat(pocheEurosFondsRepartitions?.map(f => f?.fonds));

      // old version: mandatFunds = this.recommandation.produitRecommande.modeGestion[0].allocation?.map((f: any) => f?.fonds);
    } else {
      const mandat = await this.wsReferentielMetierService.getMandatGestion(this.clientProfile.profileTypeCode);
      mandatFunds = mandat.mandatGestion?.[0]?.allocation?.map(f => f?.fonds);
    }

    const request = new GetReglesMetierProduitRequest({
      codeProduit: getCurrentProductType(),
      lireDocuments: false,
      idCanal: 4,
      idDemarche: 1
    });

    const response = await this.webApi.getReglesMetierProduit(request).toPromise();
    const fonds = response.produit.find(p => p.code?.trim() === getCurrentProductType()).fonds;

    const funds = this.wsReferentielMetierService.filterFundsByConfigFile(fonds) as Fonds[];
    // const fundsPrev = await this.wsReferentielMetierService.getFunds();

    const allFunds = funds.concat(mandatFunds);

    this.clientProfile.profileFunds.forEach((clientFund: any) => {
      const fund = allFunds.find(f => f.id === clientFund.fundId);
      if (!fund) {
        console.error(`fund with id=${clientFund.fundId} is missing.`);
      }
      this.selectedFunds.push(fund);
    });
  }

  generatePaymentsText(): void {
    this.paymentsText = this.getContractTransferText(this.clientProfile) + this.getInitialPaymentText(this.clientProfile) + this.getScheduledPaymentsText(this.clientProfile);
  }

  private getContractTransferText(profile: any): string {
    if (!profile.isWithTransfer()) {
      return '';
    }

    return `${this.labelTextPipe.transform('SUMMARY.ContractTransferText1')}<span class="fw-600 text-blue">${profile.transferContractCode}</span> ${this.labelTextPipe.transform(
      'SUMMARY.ContractTransferText2'
    )} <span class="fw-600 text-blue">${this.euroPipe.transform(profile.transferContractAmount)}</span> ${this.labelTextPipe.transform('SUMMARY.ContractTransferText3')} ${
      this.currentProductName
    } ${this.labelTextPipe.transform('SUMMARY.ContractTransferText4')} ${this.currentProductName} ${this.labelTextPipe.transform('SUMMARY.ContractTransferText5')}`;
  }

  private getInitialPaymentText(profile: any): string {
    if (!profile.isWithInitialPayment()) {
      return '';
    }

    const paymentTypeText = profile.isWithTransfer() ? 'libre' : 'initial';

    return `${this.labelTextPipe.transform('SUMMARY.InitialPaymentText1')} <span class="fw-600 text-blue">${this.labelTextPipe.transform(
      'SUMMARY.InitialPaymentText2'
    )} ${paymentTypeText}</span> de <span class="fw-600 text-blue">${this.euroPipe.transform(profile.initialPaymentAmount)}</span>`;
  }

  private getScheduledPaymentsText(profile: any): string {
    if (!profile.isWithScheduledPayments()) {
      return '.';
    }

    const paymentTypeText = profile.isWithTransfer() ? 'libre' : 'initial';
    const scheduledPaymentMinAmount = this.scheduledPaymentsAmountLimits.minAmount.replace('.', ',');
    const scheduledOnlyFragment = `${this.labelTextPipe.transform('SUMMARY.ScheduledPaymentsText1', scheduledPaymentMinAmount)}`;
    const perMonthFragment = `des <span class="fw-600 text-blue">versements programmés</span> de <span class="fw-600 text-blue">${this.euroPipe.transform(
      profile.scheduledPaymentTotalAmount()
    )} par mois</span>.`;

    if (profile.isScheduledPaymentsOnly()) {
      return `${this.labelTextPipe.transform(
        'SUMMARY.ScheduledPaymentsText2'
      )} <span class="fw-600 text-blue">versement ${paymentTypeText}</span> de <span class="fw-600 text-blue">${this.euroPipe.transform(0)}</span>, puis ${perMonthFragment} ${scheduledOnlyFragment}`;
    }

    if (!profile.isWithTransfer() && profile.isWithInitialPayment()) {
      return `${this.labelTextPipe.transform('SUMMARY.ScheduledPaymentsText3')} ${perMonthFragment}`;
    }

    if (profile.isWithTransfer() && !profile.isWithInitialPayment()) {
      return `${this.labelTextPipe.transform('SUMMARY.ScheduledPaymentsText4')} ${perMonthFragment}`;
    }

    if (profile.isWithTransfer() && profile.isWithInitialPayment()) {
      return ` ${this.labelTextPipe.transform('SUMMARY.ScheduledPaymentsText5')} ${perMonthFragment}`;
    }

    return '.';
  }

  goToRecomendation(): void {
    this.router.navigate(['my-profile/steps'], { queryParams: { userId: this.customerService.userId }, state: { showRecommendation: true } });
  }

  onOffersItem(item: Offer): void {
    const objForComparation = cleanObj(Object.assign({}, this.clientProfile?.advertisementSource));

    // check in case just return back and we need to restore the data
    if (
      this.clientProfile?.advertisementSource === undefined ||
      this.clientProfile?.advertisementSource?.promoCode === undefined ||
      this.clientProfile?.advertisementSource?.promoCode === '' ||
      JSON.stringify(objForComparation) !== JSON.stringify(this.clientProfileAdvertisementSourceOrig)
    ) {
      this.offer = item;

      if (item) {
        this.promoCodeControl.patchValue(item.promoCode);
      } else {
        this.promoCodeControl.patchValue(null);
      }
    } else if (!this.offer && !!this.offers && !!this.clientProfile?.advertisementSource?.promoCode) {
      this.offer = this.offers.find((item: Offer) => {
        return item.promoCode === this.clientProfile?.advertisementSource?.promoCode;
      });
    }
  }

  onOffersItems(items: Offer[]): void {
    this.offers = items;
  }

  get recommendationProfile(): any {
    return this.recomendedProfileType;
  }

  get selectedProfile(): any {
    return this.selectedProfileType;
  }

  get managementTypeName(): any {
    switch (this.clientProfile.managementTypeCode) {
      case constants.gestionSousMandat.id: {
        return this.labelTextPipe.transform('Summary.ManagementTypeGestionSousMandat');
      }
      case constants.gestionLibre.id: {
        return this.labelTextPipe.transform('Summary.ManagementTypeGestionLibre');
      }
      case constants.gestionHorizon.id: {
        return this.labelTextPipe.transform('Summary.ManagementTypeGestionHorizon');
      }
      default:
        return '';
    }
  }

  get managementTypeText(): any {
    switch (this.clientProfile.managementTypeCode) {
      case constants.gestionSousMandat.id: {
        return this.labelTextPipe.transform('Summary.ManagementTypeGestionSousMandatText');
      }
      case constants.gestionLibre.id: {
        return this.labelTextPipe.transform('Summary.ManagementTypeGestionLibreText');
      }
      case constants.gestionHorizon.id: {
        return this.labelTextPipe.transform('Summary.ManagementTypeGestionHorizonText');
      }
      default:
        return '';
    }
  }

  get periTaxDeductionText(): string {
    let text = '';

    if (!this.isPeri) {
      return text;
    }

    if (this.clientProfile.retirementPlan.hasTaxDeduction) {
      text = `${this.labelTextPipe.transform('Summary.PeriTaxDeductionText1')} <span class="fw-600 text-blue">${this.labelTextPipe.transform(
        'Summary.PeriTaxDeductionText2'
      )}</span> ${this.labelTextPipe.transform('Summary.PeriTaxDeductionText3')}`;
    } else {
      text = `${this.labelTextPipe.transform('Summary.PeriTaxDeductionText1')} <span class="fw-600 text-blue">${this.labelTextPipe.transform(
        'Summary.PeriTaxDeductionText4'
      )}</span> ${this.labelTextPipe.transform('Summary.PeriTaxDeductionText3')}`;
    }

    return text;
  }

  get periodText(): string {
    return this.isCems ? this.labelTextPipe.transform('Summary.PeriodText1') : this.labelTextPipe.transform('Summary.PeriodText2');
  }

  initNewFormGroup(): void {
    this.mifForm = new FormGroup({
      promoCode: new NameWithDigitsFormControl(false, false, {
        hideErrorMessages: false,
        incorrectErrorMessage: collections.messages.promoCodeIncorrectMessage
      }),
      mifAdvisorCode: new MifadvisorCodeFormControl(false, false)
    });
  }

  showConditionsModal(): void {
    this.bsModalService.show(AlertModalComponent, {
      initialState: {
        type: modalTypes.info,
        body: this.labelsDataService.getData('details.contactsRedBoxLinkContent')
      },
      class: 'modal-lg'
    });
  }

  async finalAgreeFileClick(index: number, event: PointerEvent): Promise<void> {
    event?.preventDefault();

    if (this.finalAgreeOpened.indexOf(index) === -1) {
      this.finalAgreeOpened.push(index);
    }

    openFileInNewTab(this.finalAgreeDocs[index].fichierAttache);
  }

  async finalAgreeContractFileClick(): Promise<void> {
    event?.preventDefault();

    if (this.finalAgreeContractsOpened.indexOf(1) === -1) {
      this.finalAgreeContractsOpened.push(1);
    }

    openFileInNewTab(this.finalAgreeDocsContract);
  }

  showInvalidModal(): void {
    let title = this.labelTextPipe.transform('SUMMARY.ValidationErrorLinkNotClicked');

    if (this.finalAgreeDocs.length === this.finalAgreeOpened.length) {
      title = this.labelTextPipe.transform('SUMMARY.ValidationErrorText');
    }

    this.modalItem = this.bsModalService.show(DocsModalComponent, {
      initialState: {
        type: modalTypes.error,
        title,
        transferContractCode: this.clientProfile?.transferContractCode,
        finalAgreeOpened: this.finalAgreeOpened,
        finalAgreeDocs: this.finalAgreeDocs,
        finalAgreeFileClick: this.finalAgreeFileClick,
        finalAgreeContractsOpened: this.finalAgreeContractsOpened,
        finalAgreeDocsContract: this.finalAgreeDocsContract,
        finalAgreeContractFileClick: this.finalAgreeContractFileClick,
        subscribeGpaPer1Euro: this.subscribeGpaPer1Euro,
        isPeri: this.isPeri
      },
      class: 'modal-lg'
    });

    this.subscriptionHandler.subscriptions = this.modalItem.content.onClose.subscribe(async (tryMoveNextStep: boolean) => {
      if (tryMoveNextStep && !this.isSubmitDisabled && !this.mifForm?.invalid) {
        this.goToPhoneConfirmation();
      }
    });
  }

  goToPhoneConfirmation(): void {
    let agreeConditions;

    if (this.clientProfile.transferContractCode) {
      agreeConditions = this.finalAgreeContractsOpened.length === 1 && this.finalAgreeContract && this.finalAgreeOpened?.length === this.finalAgreeDocs.length && this.finalAgree === true;
    } else {
      agreeConditions = this.finalAgreeOpened?.length === this.finalAgreeDocs.length && this.finalAgree === true;
    }

    if (this.isCems || this.isPeri || this.isCims) {
      this.checkFinalConditionCems(agreeConditions);
    } else {
      this.checkFinalConditionGpaGfd(agreeConditions);
    }
  }

  async saveGpaSubscription(model: GpaSubscriptionModel): Promise<void> {
    await this.customerService.updateGpaSubscription(model);
  }

  async clearGpaSubscription(): Promise<void> {
    await this.customerService.clearGpaSubscription();
  }

  async checkFinalConditionCems(agreeConditions: boolean): Promise<void> {
    if (agreeConditions) {
      if (this.isSpecialGpaOfferAvailable) {
        if (this.subscribeGpaPer1Euro) {
          const model: GpaSubscriptionModel = {} as GpaSubscriptionModel;

          model.health = {
            laidOffForIllness: false,
            tempUnableWork: false,
            offWorkMore30days: false,
            treatmentMore30days: false
          };

          model.reuseBeneficiaries = this.reuseBeneficiaries;
          model.isMultipliedCoverage = true;

          if (!this.reuseBeneficiaries) {
            if (this.clauseStandard) {
              model.beneficiaryClauseCode = constants.beneficiaresTypes.standard.code;

              await this.saveGpaSubscription(model);
              this.goPhoneValidation();
              return;
            }
            if (this.clauseNominative) {
              model.beneficiaryClauseCode = constants.beneficiaresTypes.nominative.code;
              if (this.isAllocatedShareSummSuccess()) {
                model.beneficiaries = this.gpaSubscriptionClauseNominativeModel;
                model.isEqualParts = this.gpaSubscriptionClauseNominativeIsEqualParts;

                await this.saveGpaSubscription(model);
                this.goPhoneValidation();
                return;
              } else {
                this.bsModalService.show(AlertModalComponent, {
                  initialState: {
                    type: modalTypes.error,
                    title: this.labelTextPipe.transform('NOMINATIVE_CLAUSE.ErrorTotalPartDifferentFrom100')
                  },
                  class: 'modal-lg'
                });
                return;
              }
            }
            if (this.clauseLibre) {
              model.beneficiaryClauseCode = constants.beneficiaresTypes.libre.code;
              model.beneficiaryFreeClauseText = this.gpaSubscriptionClauseLibreText;

              await this.saveGpaSubscription(model);
              this.goPhoneValidation();
              return;
            }
          }

          await this.saveGpaSubscription(model);
          this.goPhoneValidation();
        } else {
          await this.clearGpaSubscription();
          this.goPhoneValidation();
        }
      } else {
        await this.clearGpaSubscription();
        this.goPhoneValidation();
      }
    } else {
      this.showInvalidModal();
    }
  }

  async checkFinalConditionGpaGfd(agreeConditions: boolean): Promise<void> {
    if (agreeConditions) {
      this.goPhoneValidation();
    } else {
      this.showInvalidModal();
    }
  }

  async goPhoneValidation(): Promise<void> {
    const advertisementSourceModel = this.mifForm.getRawValue() as AdvertisementSourceDataModel;
    await this.customerService.updateAdvertisementSourceDataModel(advertisementSourceModel, true);
    this.clearTempData();

    this.router.navigate(['signature/phone-confirmation'], { queryParams: { userId: this.customerService.userId }, state: { data: this.pdfContent.nativeElement.outerHTML } });
  }

  clearTempData() {
    localStorage.removeItem(constants.localStorageKeys.tempReuseBeneficiaries);
    localStorage.removeItem(constants.localStorageKeys.tempHealthQuestionnaireForm);
    localStorage.removeItem(constants.localStorageKeys.tempClauseNominativeForm);
    localStorage.removeItem(constants.localStorageKeys.tempClauseLibreForm);
    localStorage.removeItem(constants.localStorageKeys.tempGpaBeneficiareType);
    localStorage.removeItem(constants.localStorageKeys.tempClauseNominativeFormIsEqual);
  }

  isAllocatedShareSummSuccess(): boolean {
    let summ = 0;

    this.gpaSubscriptionClauseNominativeModel.controls.forEach((fg: FormGroup) => {
      const allocatedShareControl = fg.get('allocatedShare') as NumberFormControl;
      summ += allocatedShareControl.value;
    });

    return summ === 100;
  }

  get isBeneficiariesValid(): boolean {
    if (this.reuseBeneficiaries === true) {
      return true;
    } else {
      if (this.clauseNominative) {
        return this.gpaSubscriptionClauseNominativeValid;
      } else if (this.clauseLibre) {
        return this.gpaSubscriptionClauseLibreTextValid;
      } else {
        return this.clauseStandard;
      }
    }
  }

  get isSubmitDisabled(): boolean {
    if (this.isSpecialGpaOfferAvailable) {
      if (!this.subscribeGpaPer1Euro) {
        return false;
      } else {
        if (this.gpaSubscriptionHealthQuestionsValid && this.isBeneficiariesValid) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  goToMyProfile(): void {
    this.router.navigate(['my-profile'], { queryParams: { userId: this.customerService.userId } });
  }

  goToMyContract(): void {
    this.router.navigate(['my-contract'], { queryParams: { userId: this.customerService.userId } });
  }

  goToMyBeneficiaries(): void {
    this.router.navigate(['my-beneficiaries'], { queryParams: { userId: this.customerService.userId } });
  }

  goToMyDetails(): void {
    this.router.navigate(['my-details'], { queryParams: { userId: this.customerService.userId } });
  }

  goToResidenceQuestions(): void {
    this.router.navigate(['residence-questions'], { queryParams: { userId: this.customerService.userId } });
  }

  goToFinancialSituation(): void {
    this.router.navigate(['my-details/financial-situation'], { queryParams: { userId: this.customerService.userId } });
  }

  goToBankDetails(): void {
    this.router.navigate(['my-details/bank-details'], { queryParams: { userId: this.customerService.userId } });
  }

  async getSubscriptionContract(): Promise<void> {
    const subscriptionContract = await this.customerService.getSubscriptionContract(this.customerService.userId, this.pdfContent.nativeElement.outerHTML);

    if (subscriptionContract) {
      openFileInNewTab(subscriptionContract);
    }
  }

  get isProofOfFundsFiles(): boolean {
    let files = 0;

    this.clientProfile.proofOfFunds?.forEach((proof: any) => {
      if (proof.file) {
        files++;
      }
    });

    return files > 0;
  }

  get beneficiaryClause(): any {
    return getBeneficiaryClause(this.clientProfile.beneficiaryClauseCode);
  }

  get isShowClauseStandart(): boolean {
    return this.clientProfile.beneficiaryClauseCode !== constants.beneficiaresTypes.standard.code;
  }

  get primaryBeneficiaryText(): string {
    return getPrimaryBeneficiaryText(this.clientProfile.cimsProfile.primaryBeneficiary, this.countries, this.nationalities, this.civilities, this.labelTextPipe);
  }

  get beneficiaryClauseText(): any {
    if (this.isGfd) {
      return getGfdBeneficiaryClauseText(this.clientProfile, this.countries, this.nationalities, this.civilities, this.labelTextPipe);
    }

    return getBeneficiaryClauseText(this.clientProfile, this.countries, this.nationalities, this.civilities, this.labelTextPipe);
  }

  get beneficiaryGpaClause(): any {
    return getBeneficiaryClause(this.gpaBeneficiareType !== undefined ? this.gpaBeneficiareType : this.clientProfile.beneficiaryClauseCode);
  }

  get beneficiaryGpaClauseText(): string {
    if (this.reuseBeneficiaries === undefined || this.reuseBeneficiaries) {
      return getBeneficiaryClauseText(this.clientProfile, this.countries, this.nationalities, this.civilities, this.labelTextPipe);
    } else {
      const model: GpaSubscriptionModel = {} as GpaSubscriptionModel;

      model.health = {
        laidOffForIllness: false,
        tempUnableWork: false,
        offWorkMore30days: false,
        treatmentMore30days: false
      };

      model.beneficiaries = this.gpaSubscriptionClauseNominativeModel;
      model.reuseBeneficiaries = this.reuseBeneficiaries;

      if (this.clauseStandard) {
        model.beneficiaryClauseCode = constants.beneficiaresTypes.standard.code;
      }

      if (this.clauseNominative) {
        model.beneficiaryClauseCode = constants.beneficiaresTypes.nominative.code;
        model.beneficiaries = this.gpaSubscriptionClauseNominativeModel;
        model.isEqualParts = this.gpaSubscriptionClauseNominativeIsEqualParts;
      }

      if (this.clauseLibre) {
        model.beneficiaryClauseCode = constants.beneficiaresTypes.libre.code;
        model.beneficiaryFreeClauseText = this.gpaSubscriptionClauseLibreText || this.clientProfile.gpaProfile?.beneficiaryFreeClauseText || '';
      }

      return getBeneficiaryGpaClauseText(model, this.clientProfile, this.countries, this.nationalities, this.civilities, this.labelTextPipe);
    }
  }

  get afterRepartitionText(): string {
    return getAfterRepartitionText(this.clientProfile, this.selectedFunds, this.labelTextPipe);
  }

  get isQuestion12AnsweredYes(): boolean {
    return isQuestion12AnsweredYes(this.questions, this.clientProfile);
  }

  get isShowSEPA(): boolean {
    return isShowSEPA(this.clientProfile);
  }

  get question12YesAnswerExtraText(): string {
    return question12YesAnswerExtraText(this.labelTextPipe);
  }

  get isSpecialGpaOfferAvailable(): boolean {
    return checklIsSpecialGpaOfferAvailable(this.clientProfile, this.gpaConfiguration, this.polices, this.transferableContracts);
  }

  get summaryGpaOfferText(): string {
    return this.labelTextPipe.transform(
      'Summary.UpsellATDNConditions',
      this.gpaConfiguration?.minAge,
      this.gpaConfiguration?.maxAge,
      this.gpaConfiguration?.gpaGuaranteedCapital,
      this.gpaConfiguration?.gpaCost
    );
  }
}
