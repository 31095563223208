import { ResidenceQuestionsModel } from '@extensions/user-data-extensions';
import { Component, OnInit } from '@angular/core';
import { faCheck, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { CustomerService } from '@webapi/services/customer.service';
import { ProgressBarService } from '@services/progress-bar.service';
import { Profile } from '@shared/webapi/MIF.Subscription.WebApi';
import { ProfileStepName } from '@shared/models/profile-step-name';
import { isPeri } from '@shared/extensions/extensions';

@Component({
  selector: 'app-residence-questions',
  templateUrl: './residence-questions.component.html',
  styleUrls: ['./residence-questions.component.scss']
})
export class ResidenceQuestionsComponent implements OnInit {
  public isFrenchResidence: boolean;
  public isLegallyCapable: boolean;
  public isAmericanResidence: boolean;

  public faCheck = faCheck;
  public faTimes = faTimes;
  public faInfoCircle = faInfoCircle;

  public ProfileStepName = ProfileStepName;
  public clientProfile: Profile;
  public isPeri = isPeri();

  constructor(private router: Router, private customerService: CustomerService, private progressBarService: ProgressBarService) {}

  async ngOnInit(): Promise<void> {
    this.progressBarService.sendStep(0);
    await this.restoreAnswersOnForm();
  }

  async restoreAnswersOnForm() {
    const residenceQuestionsModel = this.customerService.getResidenceQuestionsModel();
    this.clientProfile = await this.customerService.getUserData();

    if (residenceQuestionsModel) {
      if (residenceQuestionsModel.isFrenchTaxResident !== undefined && residenceQuestionsModel.isFrenchTaxResident !== null) {
        this.isFrenchResidence = residenceQuestionsModel.isFrenchTaxResident;
      }

      if (residenceQuestionsModel.isLegallyCapable !== undefined && residenceQuestionsModel.isLegallyCapable !== null) {
        this.isLegallyCapable = residenceQuestionsModel.isLegallyCapable;
      }

      if (residenceQuestionsModel.isUsCitizen !== undefined && residenceQuestionsModel.isUsCitizen !== null) {
        this.isAmericanResidence = residenceQuestionsModel.isUsCitizen;
      }
    }
  }

  switchIfFrenchResidence() {
    this.isFrenchResidence = true;
  }

  switchIfNotFrenchResidence() {
    this.isFrenchResidence = false;
  }

  switchIfAgeCapable() {
    this.isLegallyCapable = true;
  }

  switchIfAgeNotCapable() {
    this.isLegallyCapable = false;
  }

  switchIfAmericanResidence() {
    this.isAmericanResidence = true;
  }

  switchIfNotAmericanResidence() {
    this.isAmericanResidence = false;
  }

  async goToProfile() {
    const userId = await this.customerService.updateResidenceQuestionsModel({
      isFrenchTaxResident: this.isFrenchResidence,
      isLegallyCapable: this.isLegallyCapable,
      isUsCitizen: this.isAmericanResidence
    } as ResidenceQuestionsModel);

    this.router.navigate(['/my-profile'], { queryParams: { userId: userId } });
  }

  get isNotFrenchResidence(): boolean {
    return this.isFrenchResidence === false;
  }

  get isLegallyNotCapable(): boolean {
    return this.isLegallyCapable === false;
  }

  get isNotAmericanResidence(): boolean {
    return this.isAmericanResidence === false;
  }

  get isAmericanResidenceNotValidAndNotUndefined(): boolean {
    return this.isAmericanResidence !== undefined && !this.isAmericanResidenceValid;
  }

  get isAmericanResidenceValidAndNotUndefined(): boolean {
    return this.isAmericanResidence !== undefined && this.isAmericanResidenceValid;
  }

  get isAmericanResidenceValid(): boolean {
    if (!this.isPeri) {
      return this.isAmericanResidence !== undefined && this.isAmericanResidence === false;
    } else {
      // MIFS-665: Make FATCA Question Optional on PERI Journey
      return this.isAmericanResidence !== undefined;
    }
  }

  get submitDisabled(): boolean {
    let isAmericanResidenceOk = !this.isPeri ? this.isAmericanResidenceValidAndNotUndefined : true;

    return (
      isAmericanResidenceOk === false ||
      this.isLegallyCapable === undefined ||
      this.isFrenchResidence === undefined ||
      this.isLegallyCapable === false ||
      this.isFrenchResidence === false
    );
  }
}
