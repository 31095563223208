import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { ProgressBarService } from '@services/progress-bar.service';
import { Subscription } from 'rxjs';
import { constants } from '@constants/constants';
import { CustomerService } from '@webapi/services/customer.service';
import { NavigationEnd, Router } from '@angular/router';
import { SubscriptionHandler } from '@components/subscriptionHandler';
import { getStep1Name, getStep2Name, getStep5Name } from '@extensions/env-related-extensions';
import { collections } from '@constants/collections';
import { General, Profile } from '@webapi/MIF.Subscription.WebApi';
import { SettingsService } from '@webapi/services/settings.service';
import { getCurrentProductName, getCurrentProductType, isCems, isCims, isGfd, isGpa, isPeri } from '@extensions/extensions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  faLongArrowLeft = faLongArrowAltLeft;
  subscription: Subscription;
  progressWidth: number;
  firstStepsPercentCoefficient = 9;
  normalPercentCoefficient = 10;
  currentStep = 0;
  identificationData: IdentificationData;
  subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
  collections = collections;
  getStep1Name = getStep1Name;
  getStep2Name = getStep2Name;
  getStep5Name = getStep5Name;
  isCems = isCems();
  isCims = isCims();
  isGpa = isGpa();
  isGfd = isGfd();
  isPeri = isPeri();
  productType: string;
  productName: string;
  clientProfile: Profile;
  generalConfiguration: General;

  constructor(
    private router: Router,
    public progressBarService: ProgressBarService,
    public customerService: CustomerService,
    public settingsService: SettingsService,
    private gtmService: GoogleTagManagerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.productType = getCurrentProductType();
    this.productName = getCurrentProductName();
    this.clientProfile = await this.customerService.getUserData();
    this.generalConfiguration = await this.settingsService.getGeneralConfiguration();

    this.subscriptionHandler.subscriptions = this.progressBarService.getStep().subscribe((step: any) => {
      this.currentStep = step.text;
      if (step.text <= 12) {
        this.progressWidth = step.text * this.firstStepsPercentCoefficient;
      } else {
        this.progressWidth = step.text * this.normalPercentCoefficient;
      }
    });

    this.subscriptionHandler.subscriptions = this.router.events.subscribe(async val => {
      if (val instanceof NavigationEnd) {
        if (val.urlAfterRedirects.indexOf(constants.homePage) === -1) {
          const profile = await this.customerService.getUserData();

          this.identificationData = {
            firstName: profile?.person.firstName,
            lastName: profile?.person.lastName,
            code: profile?.fileNumber
          };
        } else {
          this.identificationData = null;
        }

        this.pushGtmTag(val, this.identificationData);
      }
    });

    this.subscriptionHandler.subscriptions = this.customerService.userDataOnCleared().subscribe((cleared: boolean) => {
      if (cleared) {
        this.identificationData = null;
      }
    });
  }

  pushGtmTag(val: NavigationEnd, identificationData?: any): void {
    let pageUrl;

    if (val.url.indexOf('?userId=') !== -1) {
      pageUrl = val.url.split('?userId=')[0];
    } else {
      pageUrl = val.url;
    }

    const gtmTag = {
      // event: 'page',
      produit: this.productType,
      page: pageUrl,
      numeroDossier: identificationData?.code
    };

    this.gtmService
      .pushTag(gtmTag)
      .then(() => {
        console.log(`gtm tag sent: ${gtmTag.produit} - ${gtmTag.page}`);
      })
      .catch(error => {
        console.error(`error sending gtm tag: ${error}`);
      });
  }

  async step2Click($event: any): Promise<void> {
    $event.stopPropagation();
    $event.preventDefault();
    const clientProfile = await this.customerService.getUserData();

    if (clientProfile.isRecommendationsFollowed) {
      this.router.navigate(['my-contract'], { queryParams: { userId: clientProfile.uniqueId }, state: { mismatchCheck: true } });
    } else {
      this.router.navigate(['my-contract'], { queryParams: { userId: clientProfile.uniqueId } });
    }
  }

  ngOnDestroy(): void {
    this.subscriptionHandler.unsubscribeAll();
  }

  get isMyProfileViewed(): boolean {
    return this.currentStep >= constants.steps.myProfile.countOfTheSteps;
  }

  get isMyProfileInactive(): boolean {
    return this.currentStep < constants.steps.myProfile.countOfTheSteps;
  }

  get isMyContractViewed(): boolean {
    return this.currentStep >= constants.steps.myContracts.countOfTheSteps;
  }

  get isMyContractInactive(): boolean {
    return this.currentStep < constants.steps.myContracts.countOfTheSteps;
  }

  get isMyBeneficiariesViewed(): boolean {
    return this.currentStep >= constants.steps.myBeneficiaries.countOfTheSteps;
  }

  get isMyBeneficiariesInactive(): boolean {
    return this.currentStep < constants.steps.myBeneficiaries.countOfTheSteps;
  }

  get isIdentificationData(): any {
    return !!this.identificationData;
  }

  get isMyDetailsViewed(): boolean {
    return this.currentStep >= constants.steps.myDetails.countOfTheSteps;
  }

  get isMyDetailsInactive(): boolean {
    return this.currentStep < constants.steps.myDetails.countOfTheSteps;
  }

  get isSignatureViewed(): boolean {
    return this.currentStep >= constants.steps.signature.countOfTheSteps;
  }

  get isSignatureInactive(): boolean {
    return this.currentStep < constants.steps.signature.countOfTheSteps;
  }

  onRetourAuProduitCemsClick(): void {
    window.open(this.generalConfiguration.cemsSite, '_blank');
  }

  onRetourAuProduitGpaClick(): void {
    window.open(this.generalConfiguration.gpaSite, '_blank');
  }

  onRetourAuProduitGfdClick(): void {
    window.open(this.generalConfiguration.gfdSite, '_blank');
  }

  onRetourAuProduitPeriClick(): void {
    window.open(this.generalConfiguration.periSite, '_blank');
  }

  onRetourAuProduitCimsClick(): void {
    window.open(this.generalConfiguration.cimsSite, '_blank');
  }

  onLogoCLick(): void {
    window.open(this.generalConfiguration.originalWebSite, '_blank');
  }

  onRouteCLick(route: string): void {
    if (this.customerService.userId) {
      this.router.navigate([route], { queryParams: { userId: this.customerService.userId } });
    } else {
      this.router.navigate([route]);
    }
  }
}

export class IdentificationData {
  firstName: string;
  lastName: string;
  code: string;
}
