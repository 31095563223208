<app-i-advize *ngIf="clientProfile" [pageType]="ProfileStepName.ResidenceQuestions" [clientProfile]="clientProfile"></app-i-advize>
<app-kameleoon></app-kameleoon>

<div class="residence-questions">
  <div class="text-center mt-5 mb-5">
    <h5>{{ 'ELIGIBITY.QuestionsToDefineYourProfile' | labelText }}</h5>
  </div>
  <div>
    <div class="card">
      <div class="card-body mt-4 ml-3">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
            <div class="question-block">
              <label>
                {{ 'QUESTIONNAIRE.AreYouFrenchTaxResident' | labelText }}
                <app-tooltip [title]="'Home.AreYouFrenchTaxResidentTooltipTitle' | labelText" [text]="'Home.AreYouFrenchTaxResidentTooltipText' | labelText" [tooltipPosition]="'bottom'">
                </app-tooltip>
              </label>
              <div class="buttons-block">
                <button
                  class="btn btn-primary radio-label mr-2"
                  type="button"
                  [ngClass]="{ 'active-btn': isFrenchResidence, 'active-btn2': isFrenchResidence }"
                  (click)="switchIfFrenchResidence()"
                  [disabled]="(isLegallyNotCapable || isAmericanResidenceNotValidAndNotUndefined) && (isNotFrenchResidence || isFrenchResidence === undefined)"
                >
                  {{ 'Common.Yes' | labelText }}
                </button>
                <button
                  class="btn btn-primary radio-label"
                  type="button"
                  [ngClass]="{ 'error-btn': isNotFrenchResidence }"
                  (click)="switchIfNotFrenchResidence()"
                  [disabled]="isLegallyNotCapable || isAmericanResidenceNotValidAndNotUndefined"
                >
                  {{ 'Common.No' | labelText }}
                </button>
                <span class="check-mark" *ngIf="isFrenchResidence"><fa-icon [icon]="faCheck"></fa-icon></span>
                <span class="check-mark error" *ngIf="isNotFrenchResidence"><fa-icon [icon]="faTimes"></fa-icon></span>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="isNotFrenchResidence">
            <div class="error-block">
              <span class="text">
                {{ 'Common.YouCannotSubscribeOnline' | labelText }}
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
            <div class="question-block">
              <label>
                {{ 'QUESTIONNAIRE.AreYouMajor' | labelText }}
                <app-tooltip [title]="'Home.AreYouMajorTooltipTitle' | labelText" [text]="'Home.AreYouMajorTooltipText' | labelText" [tooltipPosition]="'bottom'"></app-tooltip>
              </label>
              <div class="buttons-block">
                <button
                  class="btn btn-primary radio-label mr-2"
                  type="button"
                  [ngClass]="{ 'active-btn': isLegallyCapable, 'active-btn2': isLegallyCapable }"
                  (click)="switchIfAgeCapable()"
                  [disabled]="(isNotFrenchResidence || isAmericanResidenceNotValidAndNotUndefined) && (isLegallyNotCapable || isLegallyCapable === undefined)"
                >
                  {{ 'Common.Yes' | labelText }}
                </button>
                <button
                  class="btn btn-primary radio-label"
                  type="button"
                  [ngClass]="{ 'error-btn': isLegallyNotCapable }"
                  (click)="switchIfAgeNotCapable()"
                  [disabled]="isNotFrenchResidence || isAmericanResidenceNotValidAndNotUndefined"
                >
                  {{ 'Common.No' | labelText }}
                </button>
                <span class="check-mark" *ngIf="isLegallyCapable"><fa-icon [icon]="faCheck"></fa-icon></span>
                <span class="check-mark error" *ngIf="isLegallyNotCapable"><fa-icon [icon]="faTimes"></fa-icon></span>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="isLegallyNotCapable">
            <div class="error-block">
              <span class="text">
                {{ 'Common.YouCannotSubscribeOnline' | labelText }}
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
            <div class="question-block">
              <label>
                {{ 'QUESTIONNAIRE.AreYouAUsCitizen' | labelText }}
                <app-tooltip [title]="'Home.AreYouAUsCitizenTooltipTitle' | labelText" [text]="'Home.AreYouAUsCitizenTooltipText' | labelText" [tooltipPosition]="'bottom'"></app-tooltip>
              </label>

              <div class="buttons-block">
                <button
                  class="btn btn-primary radio-label mr-2"
                  type="button"
                  [ngClass]="{
                    'active-btn': isAmericanResidence && isAmericanResidenceValid,
                    'active-btn2': isAmericanResidenceValidAndNotUndefined && isAmericanResidence !== false,
                    'error-btn': isAmericanResidenceNotValidAndNotUndefined
                  }"
                  (click)="switchIfAmericanResidence()"
                  [disabled]="isNotFrenchResidence || isLegallyNotCapable"
                >
                  {{ 'Common.Yes' | labelText }}
                </button>
                <button
                  class="btn btn-primary radio-label"
                  type="button"
                  [ngClass]="{ 'active-btn': isNotAmericanResidence, 'active-btn2': isNotAmericanResidence }"
                  (click)="switchIfNotAmericanResidence()"
                  [disabled]="isNotFrenchResidence || isLegallyNotCapable"
                >
                  {{ 'Common.No' | labelText }}
                </button>
                <span *ngIf="isAmericanResidenceValidAndNotUndefined" class="check-mark"><fa-icon [icon]="faCheck"></fa-icon></span>
                <span *ngIf="isAmericanResidenceNotValidAndNotUndefined" class="check-mark error"><fa-icon [icon]="faTimes"></fa-icon></span>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="isAmericanResidenceNotValidAndNotUndefined">
            <div class="error-block">
              <span class="text">
                {{ 'Common.YouCannotSubscribeOnline' | labelText }}
              </span>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12">
            <button type="button" class="btn btn-primary validation-button" (click)="goToProfile()" [disabled]="submitDisabled">
              {{ 'Common.Validate' | labelText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
